<template>
  <div style="margin-left: auto; margin-right: auto;" class="d-flex justify-content-between mt-5 mb-4">
    <div class="d-flex">
      <!-- Search by name patient -->
      <input type="text" class="search_input" v-model="search" @input="SearchPatient" placeholder="Search by name">
    </div>
  </div>
  


  <!-- Cards section -->
  <div class="d-flex justify-content-between">
    <div v-for="patient in patients.patientInfo" :key="patient.id" class="custom-card mb-3">
    <router-link :to="{ name: 'AdminPatientDetails', params: { id: patient.id } }" class="card-link">
        <div class="card-body d-flex align-items-center justify-content-between">
            <div class="circle">
                {{ getInitials(patient.firstName, patient.lastName) }}
            </div>
            <div style="color: black;" class="card-content">
                <h5 class="card-title">{{ patient.firstName }} {{ patient.lastName }}</h5>
            </div>
            <div style="color: black;"  class="card-info">
                    <p><strong>Numéro:</strong> {{ patient.numero }}</p>
                    <p><strong>Email:</strong> {{ patient.email }}</p>
                </div>
        </div>
    </router-link>
</div>
  </div>



  <!-- Pagination -->
  <div class="d-flex justify-content-center mt-5">
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <a style="border:none;" class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item"><a style="border:none; font-size: 15px;" class="page-link" href="#">1</a></li>
        <li class="page-item"><a style="border:none; font-size: 15px;" class="page-link" href="#">2</a></li>
        <li class="page-item"><a style="border:none; font-size: 15px;" class="page-link" href="#">3</a></li>
        <li class="page-item">
          <a style="border:none;" class="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      showForm: false,
      search: '',
      patients: [],
      selectedPatients: [],
      originalPatients: [] // Ajout de la variable pour stocker la liste originale des patients
    };
  },

  mounted(){
    this.fetchPatients();
  },

  methods: {
    toggleSelection(patient) {
      if (this.selectedPatients.includes(patient)) {
        this.selectedPatients = this.selectedPatients.filter(
          selectedPatient => selectedPatient !== patient
        );
      } else {
        this.selectedPatients.push(patient);
      }
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedPatients = this.patients.slice();
      } else {
        this.selectedPatients = [];
      }
    },
    showSelectedPatients() {
      this.originalPatients = this.patients.slice(); // Sauvegarde de la liste originale
      this.patients = this.selectedPatients.slice(); // Affichage uniquement des patients sélectionnés
    },
    fetchPatients(){
      // fetch doctor info
      const token = localStorage.getItem('token')

      axios.get('http://13.49.196.22/doctor/patient-lists', {
          headers: {
              Authorization: `${token}`
          }
      })
      .then((response) => {
          console.log(response.data)
          
          this.patients = response.data
      })
      .catch((error) => {
          console.log(error)
      })
    },
    resetPatients() {
      this.patients = this.originalPatients.slice(); // Réinitialisation de la liste des patients
    },
    SearchPatient() {
      this.patients = this.patients.filter(patient => patient.name.includes(this.search));
    },
    getInitials(firstName, lastName) {
      return firstName.charAt(0) + lastName.charAt(0);
    }
  }
};
</script>

<style scoped>
.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
    background-color: #fff;
    min-width: 500px;
    margin: 10px;
}

.circle {
    width: 60px;
    height: 60px;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    background-color: #007bff65;
    box-shadow: inset 0 0 0 3px #FFF;
    border: 2px solid #007bff ;
    border-radius: 50%;
    margin-right: 15px;
}

.card-title {
    font-size: 18px;
    margin: 0;
}

.card-info {
    margin: 0;
    text-align: left;
}


.button_patient {
  background-color: white;
  color: #4E6DCE;
  border: 1px solid #4E6DCE;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  margin-right: 10px;
  width: 70px;
}

.search_input {
  border: 1px solid #4E6DCE;
  border-radius: 5px;
  padding: 5px;
  width: 200px;
}

.add_patient {
  background-color: #4E6DCE;
  color: white;
  border: 1px solid #4E6DCE;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.show_form_filter {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 80%;
}

.show_form_filter input {
  border: 1px solid #4E6DCE;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
}

.show_form_filter select {
  border: 1px solid #4E6DCE;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
}

.show_form_filter label {
  font-weight: 400;
}

.show_form_filter div {
  margin-bottom: 20px;
}
</style>
