<template>
  <div class="mt-5 container">
    <h5>Vos Rendez-Vous</h5>
  </div>

  <div class="navigation"> 
    <input v-model="searchQuery" @input="searchAppointments" @keyup.delete="clearSearch" class="input_search_nav mr-5" placeholder="Recherchez un Rendez-Vous" type="search">
  <button @click="sortByDate" class="filtre_dashboard_patient">Plus Récent</button>
  <button @click="sortByDoctor" class="filtre_dashboard_patient">Plus Ancien</button>
</div>


  <div class="mt-5">
    <table class="custom-table">
      <thead>
        <tr>
          <th>Date et Heure</th>
          <th>Docteur</th>
          <th>Service</th>
          <th>Statut</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(appointment, index) in paginatedAppointments" :key="index">
          <td>{{ formatDate(appointment.start) }}</td>
          <td>{{ appointment.doctorNom }} {{ appointment.doctorPrenom }} </td>
          <td>{{ appointment.service }}</td>
          <td>{{ appointment.status }}</td>
          <td>
            <button @click="cancelAppointment(appointment._id)">Annuler</button>
            <button @click="deleteAppointment(appointment._id)">Supprimer</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <button @click="previousPage" :disabled="currentPage === 1">Précédent</button>
      <button @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      appointments: [],
      pageSize: 10,
      currentPage: 1,
      searchQuery: ''
    };
  },
  created() {
    // Appel à l'API pour récupérer les rendez-vous du patient
    this.fetchAppointments();
  },
  computed: {
    paginatedAppointments() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.appointments.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.appointments.length / this.pageSize);
    }
  },
  watch: {
    searchQuery() {
      // Déclencher automatiquement le filtrage lorsque searchQuery change
      this.searchAppointments();
    }
  },
  methods: {
    clearSearch() {
    if (!this.searchQuery) {
      this.fetchAppointments(); // Recharger tous les rendez-vous
    }
  },
    fetchAppointments() {
      axios.get('http://13.49.196.22/events/patient', {
        headers: {
            'Authorization' : localStorage.getItem('token')
        }
      })
        .then(response => {
          this.appointments = response.data;
          console.log(this.appointments)
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des rendez-vous :', error);
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    cancelAppointment(appointmentId) {
      // Logique pour annuler le rendez-vous
      console.log('Rendez-vous annulé :', appointmentId);
    },
    deleteAppointment(appointmentId) {
      // Logique pour supprimer le rendez-vous
      console.log('Rendez-vous supprimé :', appointmentId);
      // Envoyer une requête DELETE à l'API pour supprimer le rendez-vous
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    searchAppointments() {
  const normalizedQuery = this.searchQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const filteredAppointments = this.appointments.filter(appointment => {
    const normalizedDoctorId = appointment.doctorId.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const normalizedDescription = appointment.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const normalizedTitle = appointment.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return (
      normalizedDoctorId.includes(normalizedQuery) ||
      normalizedDescription.includes(normalizedQuery) ||
      normalizedTitle.includes(normalizedQuery)
    );
  });
  this.appointments = filteredAppointments;
},

    sortByDate() {
      // Logique pour trier les rendez-vous par date
      this.appointments.sort((a, b) => new Date(b.start) - new Date(a.start));
    },
    sortByDoctor() {
      // Logique pour trier les rendez-vous par date dans l'ordre ascendant
      this.appointments.sort((a, b) => new Date(a.start) - new Date(b.start));
    }
  }
};
</script>


  
  <style scoped>

.navigation {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Exemple de box shadow avec une faible opacité */
  background-color: rgba(204, 204, 204, 0.49);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding:  10px;
}

.input_search_nav{
  background-color: white;
border-radius: 4px;
border: none;
width: 50%;
padding: 5px;
}

.filtre_dashboard_patient{
  background-color: white;
  padding: 5px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  width: 15%;
}

.custom-table {
  width: 80%;
  border-collapse: collapse;
  margin-left: auto;
margin-right: auto;
}

.custom-table th,
.custom-table td {
  padding: 8px;
  text-align: center;
 
}

.custom-table th {
  background-color: #f2f2f2; /* Couleur de fond des en-têtes */
  font-weight: bold;
  color: #333; /* Couleur du texte */
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Couleur de fond des lignes paires */
}

.custom-table tbody tr:hover {
  background-color: #e2e2e2; /* Couleur de fond au survol des lignes */
}

/* Style pour la première colonne (Date) */
.custom-table tbody td:first-child {
  font-style: italic;
}

/* Style pour la dernière colonne (Statut) */
.custom-table tbody td:last-child {
  color: green; /* Couleur du texte */
  font-weight: bold;
}

  </style>
  