<template>

<div class="container mt-5">
        <div class="row">
            <div class="col-md-8">
                <div class="card-admin">
                    <div class="row">
                        <div style="color: white;" class="col-md-7 mt-5 ">
                            <h6>Welcome Back !</h6>
                            <h4>DR. {{ nom }} {{ prenom }} </h4>
                            <span style="color: white;"> Vous Avez </span> <span style="color: yellow;"> {{ todayEvents
                                }} RDV Aujourd'hui !</span>
                        </div>
                        <div class="col-md-5">
                            <img src="../assets/hand-drawn-doctor-cartoon-illustration_23-2150696179-removebg-preview.png"
                                style="height: 200px; width: auto; ">
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-6">
                        <div style="background-color: rgb(230 233 241 / 49%); border-radius: 10px; padding: 10px; height: 100%;">
                            <span>Todays appointment</span>

                            <div class="mt-3" style="border-bottom: 1px solid #ccc; padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span>8:00</span>
                                </div>
                            </div>
                            <div class="mt-3" style="border-bottom: 1px solid #ccc; padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span>8:00</span>
                                </div>
                            </div>
                            <div class="mt-3" style="border-bottom: 1px solid #ccc; padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span>8:00</span>
                                </div>
                            </div>
                            <div class="mt-3" style=" padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span>8:00</span>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div style="background-color: rgb(230 233 241 / 49%); border-radius: 10px; padding: 10px;">
                            <span style="text-align: left; ">
                                Revenue
                            </span>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div style="border-right: 1px solid #ccc;">
                                        <h6 style="  font-size: 25px;">
                                            3000€
                                        </h6>
                                        <span>
                                            Ce mois ci
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <h6 style="  font-size: 25px;">
                                            300€
                                        </h6>
                                        <span>
                                            Cette semaine
                                        </span>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="mt-3">
    <div style="background-color: rgb(230 233 241 / 49%); border-radius: 10px;     padding: 15px 12px;">
      <div class="d-flex justify-content-between">
        <h6>Analyse</h6>
        <h6>Cette semaine</h6>
      </div>
      <div class="d-flex justify-content-around mt-3">
        <div v-for="day in days" :key="day.name" class="day-bar-container">
          <div class="day-label">{{ day.name }}</div>
          <div class="bar-container">
            <div
              class="day-bar"
              :style="{ height: day.appointments + 'px', backgroundColor: '#007bff' }"
              @mouseover="showValue(day.appointments, 'Rendez-vous')"
              @mouseout="hideValue"
            ></div>
            <div
              class="day-bar"
              :style="{ height: day.cancellations + 'px', backgroundColor: '#ff7f0e' }"
              @mouseover="showValue(day.cancellations, 'Rendez-vous annulé')"
              @mouseout="hideValue"
            ></div>
          </div>
        </div>
      </div>
      <div  class="legend mt-3">
        <div class="legend-item">
          <div class="color-box" style="background-color: #007bff;"></div>
          <span style="font-size: 14px;">Rendez-vous</span>
        </div>
        <div class="legend-item">
          <div class="color-box" style="background-color: #ff7f0e;"></div>
          <span style="font-size: 14px;">Rendez-vous annulé</span>
        </div>
      </div>

    </div>
   
  </div>
                    </div>
                </div>



            </div>
            <div class="col-md-4">
                <div style="height: 100%; background-color: rgb(230 233 241 / 49%); padding: 20px;border-radius: 10px;">
                    <span style="float: left;">Profile</span>
    <!-- Profil section -->
  <div class="mt-4">
    
    <img src="https://www.carehope.edu/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdlhkv1kpd%2Fimage%2Fupload%2Fv1685086209%2Fthe_Meaning_of_Patient_Care_feat_img_4b27f38850.png&w=1920&q=100" alt="Photo de profil" style="width: 100px; height: 100px; border-radius: 50%;     box-shadow: rgba(149, 157, 165, 0.6) 0px 8px 24px;
}">
<div class="profile-details">
          <h6>Dr. {{ nom }} {{ prenom }}</h6>
          <p>{{ specialite }}</p>
          <div class="d-flex align-items-center">
  <div style="flex: 1; padding: 10px;">
    <p>Nombre de patients</p>
    <span>3000</span>
  </div>
  <div style="border-right: 1px solid #ccc; "></div>
  <div style="flex: 1; padding: 10px;">
    <p style="text-align: center;">Note:</p>
    <span style="text-align: center;">4.9</span>
  </div>
</div>
        </div>

  </div>

    <!-- Alertes section -->
    <div class="alert-section mt-3">
        <span style="float: left;">Alerte</span>
      <div class="mt-5" style="border-bottom: 1px solid #ccc; padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span> 30min</span>
                                </div>
                            </div>
                            <div class="mt-3" style="border-bottom: 1px solid #ccc; padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span> 30min</span>
                                </div>
                            </div>
                            <div class="mt-3" style="border-bottom: 1px solid #ccc; padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span> 30min</span>
                                </div>
                            </div>
                            <div class="mt-3" style=" padding: 5px;">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img style="height: 46px; width: 46px; border-radius: 50%;"
                                        src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg">
                                    <div class="text-center">
                                        <span style="display: block;">Enzo Viana</span>
                                        <span
                                            style="display: block; font-size: 12px; text-align-last: center;">Consultation</span>
                                    </div>
                                    <span> 30min</span>
                                </div>
                            </div>
    </div>

   
  </div>
            </div>
        </div>
    </div>

 </template>
 
 <script>

import axios from 'axios'

export default {
  name: 'App',

  data() {
    return {
        currentMonth: '',
      daysOfWeek: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
      selectedDate: new Date(),
        nom: '',
        prenom: '',
        events: [],
        todayEvents: 0,
        weekEvents: 0,
        monthEvents: 0,
        yearEvents: 0, 
        firstFourEvents: [],
        days: [
        { name: 'Lun', appointments: 50, cancellations: 20 },
        { name: 'Mar', appointments: 70, cancellations: 15 },
        { name: 'Mer', appointments: 30, cancellations: 10 },
        { name: 'Jeu', appointments: 60, cancellations: 25 },
        { name: 'Ven', appointments: 90, cancellations: 30 },
        { name: 'Sam', appointments: 40, cancellations: 5 },
        { name: 'Dim', appointments: 20, cancellations: 0 }
      ],
    }
  },
  methods: {
    showValue(value, label) {
      this.hoverValue = value;
      this.hoverLabel = label;
    },
    hideValue() {
      this.hoverValue = null;
      this.hoverLabel = '';
    },
    getMonth() {
  const currentDate = new Date();
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  const currentMonth = months[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();

  this.currentMonth = currentMonth + " " + currentYear;
  
  console.log(this.currentMonth);
}, 

datesForDayOfWeek(dayOfWeek) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  const dates = [];
  const day = dayOfWeek === 'Lun' ? 1 : // Monday
              dayOfWeek === 'Mar' ? 2 : // Tuesday
              dayOfWeek === 'Mer' ? 3 : // Wednesday
              dayOfWeek === 'Jeu' ? 4 : // Thursday
              dayOfWeek === 'Ven' ? 5 : // Friday
              dayOfWeek === 'Sam' ? 6 : 0; // Saturday

  let startDate = new Date(firstDayOfMonth);

  // Trouver le jour de la semaine correspondant
  while (startDate.getDay() !== day) {
    startDate.setDate(startDate.getDate() + 1);
  }

  // S'assurer que la date trouvée est dans le mois en cours
  while (startDate.getMonth() !== currentMonth) {
    startDate.setDate(startDate.getDate() + 7); // Ajouter une semaine
  }

  // Collecter toutes les dates du même jour de la semaine dans le mois
  while (startDate <= lastDayOfMonth) {
    dates.push(new Date(startDate)); // Copie de l'objet date pour éviter les problèmes de référence
    startDate.setDate(startDate.getDate() + 7); // Passer à la semaine suivante
  }

  return dates;
},
selectDate(date) {
      this.selectedDate = date;
      this.getFirstFourEventsForSelectedDate(this.selectedDate);
      console.log(this.selectedDate);
    },

    isSelected(date) {
  const selected = this.selectedDate;
  return selected && 
         date.getDate() === selected.getDate() &&
         date.getMonth() === selected.getMonth() &&
         date.getFullYear() === selected.getFullYear();
}
,

    defaultselectDate() {
      const currentDate = new Date();
      this.selectedDate = currentDate;
      console.log(this.selectedDate);
    },

    fetchDoctorinfo(){
        // fetch doctor info
        const token = localStorage.getItem('token')

        axios.get('http://13.49.196.22/profile/doctor', {
            headers: {
                Authorization: `${token}`
            }
        })
        .then((response) => {
            console.log(response.data)
            this.nom = response.data.nom
            this.prenom = response.data.prenom
            this.fetchEvents();
        })
        .catch((error) => {
            console.log(error)
        })
},

    fetchEvents () {
      // fetch events for today
      axios.get('http://13.49.196.22/events/', {
        headers: {
            'Authorization' : localStorage.getItem('token')
        }
    })
    .then(response => {
        
        console.log(response.data)
        this.events = response.data ;
        this.counttodayEvents();

    })
    .catch(error => {
        console.error('There was an error!', error);
    });

    }  ,
    getFirstFourEventsForSelectedDate(selectedDate) {
        // Filtrer les événements pour la date sélectionnée
        const eventsForSelectedDate = this.events.filter(event => {
            const eventDate = new Date(event.start);
            return eventDate.toDateString() === selectedDate.toDateString();
        });

        // Formater les heures de début et de fin des événements
        eventsForSelectedDate.forEach(event => {
            const startDate = new Date(event.start);
            const endDate = new Date(event.end);
            event.formattedStartTime = startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            event.formattedEndTime = endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        });

        // Récupérer les 4 premiers événements pour la date sélectionnée
        this.firstFourEvents = eventsForSelectedDate.slice(0, 4);
    },

    counttodayEvents(){
    // count events for today
    const today = new Date();
    const todayEvents = this.events.filter(event => {
        const eventDate = new Date(event.start);
        return eventDate.getDate() === today.getDate() &&
               eventDate.getMonth() === today.getMonth() &&
               eventDate.getFullYear() === today.getFullYear();
    });
    this.todayEvents = todayEvents.length;
    this.countThisWeekEvents();
    this.countThisMonthEvents();
    this.countThisYearEvents();
}, 
countThisWeekEvents() {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the first day of the week (Sunday)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to the last day of the week (Saturday)

    const weekEvents = this.events.filter(event => {
        const eventDate = new Date(event.start);
        return eventDate >= startOfWeek && eventDate <= endOfWeek;
    });
    this.weekEvents = weekEvents.length;

    console.log('Week events', weekEvents.length);
},
countThisMonthEvents() {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const monthEvents = this.events.filter(event => {
        const eventDate = new Date(event.start);
        return eventDate >= startOfMonth && eventDate <= endOfMonth;
    });
    this.monthEvents = monthEvents.length;

    console.log('Month events', monthEvents.length);
},
countThisYearEvents() {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const endOfYear = new Date(today.getFullYear(), 11, 31); // 11 instead of 12 for December

    const yearEvents = this.events.filter(event => {
        const eventDate = new Date(event.start);
        return eventDate >= startOfYear && eventDate <= endOfYear;
    });
    this.yearEvents = yearEvents.length;

    console.log('Year events', yearEvents.length);
}


    },
  mounted() {
    this.getMonth();
    this.defaultselectDate();
    this.fetchDoctorinfo();
   

  }
}
</script>



 <style>


.profile-section {
  display: flex;
  align-items: center;
}

.profile-info {
  margin-right: 20px;
}

.additional-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-section {
  padding-top: 20px;
}

.rdv-info {
  display: flex;
  align-items: center;
}

.rdv-details {
  margin-left: 10px;
}
.day-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
.day-label {
  margin-bottom: 5px;
  text-align: center;
}
.bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.day-bar {
  width: 10px;
  border-radius: 5px;
  margin: 2px 0;
  transition: background-color 0.3s;
  cursor: pointer;
}
.day-bar:hover {
  background-color: #0056b3;
}
.hover-value {
  margin-top: 10px;
  font-weight: bold;
}
.legend {
  display: flex;
  justify-content: flex-start;
}
.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.color-box {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
  border-radius: 50%;
}

@media (min-width: 768px) {
    .col-md-2_Jours {
        width: 14.666667%;
        display: flex-inline;
    }

 .button-nav {
     display: block;
     padding: 10px;
     text-decoration: none;
     color: black;
     border-bottom: 1px solid #ccc;
 }
 
 .button-nav:hover {
     background-color: #f0f0f0;
 }

 .card-admin{
        background-color: rgb(4, 56, 115);
        height: 200px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 #ccc;
 }

 .card-report{
       padding: 20px;
       box-shadow: 0 0 10px 0 #ccc;
       margin-top: 20px;
        border-radius: 10px;
        
 }

 .card_report_data{
     padding: 10px;
     box-shadow: 0 0 10px 0 #ccc;
     border-radius: 10px;
 }

 .key_number{
   
     font-size: 45px;
     font-weight: bold;
 }

 .patient-card{
     height: 60px;
     margin-bottom: 10px;
     padding: 10px;
     background-color: #b3e3f6;
     border-radius: 10px;
     box-shadow: 0 0 10px 0 #ccc;
 }


 .patient-card_yellow {
     background-color: #f6e3b3;
     height: 60px;
     margin-bottom: 10px;
     padding: 10px;
     border-radius: 10px;
     box-shadow: 0 0 10px 0 #ccc;
 }

 .patient-card_purple {
     background-color: #f6b3e3;
     height: 60px;
     margin-bottom: 10px;
     padding: 10px;
     border-radius: 10px;
     box-shadow: 0 0 10px 0 #ccc;
 }

 .patient-card_green {
     background-color: #b3f6e3;
     height: 60px;
     margin-bottom: 10px;
     padding: 10px;
     border-radius: 10px;
     box-shadow: 0 0 10px 0 #ccc;
 }



 .button_more_patient {
        background-color: rgb(4, 56, 115);
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 15px;
        cursor: pointer;
        font-weight: bold;
        font-size: small;
        color: white;
 }

 .dropdown_button {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
 }

 .nav_admin {
        background-color: #007bff;
        box-shadow: 5px 0 5px -5px #ccc;
        padding: 6px;
        min-width: 200px;
 }

 



 .card_chart {
     padding: 10px;
     box-shadow: 0 0 10px 0 #ccc;
     border-radius: 10px;
     margin-top: 20px;
     margin-bottom: 20px;
 }

 .card_datepicker {
        padding: 10px;
        box-shadow: 0 0 10px 0 #ccc;
        border-radius: 10px;
        margin-bottom: 20px;
 }

 .svg_dashboard {
     height: 25px;
     width: 25px;
     border-radius: 5px;
     padding: 5px;
     box-shadow: 0 0 10px 0 #ccc;
    
 }

 .col-md-2_Jours {
    width: 14.666667%;
    display: flex-inline;
 }

 .Jours_semaine {
    font-size: 13px;
font-weight: 600;
 }
.other-month {
  color: gray; /* Couleur différente pour les dates en dehors du mois actuel */
}

.date{
    padding: 5px;
    margin: 5px;
    background-color: white;
    cursor: pointer;
}

.selected {
  border: 1px solid rgb(4, 56, 115);
  border-radius: 10px;
  color: rgb(4, 56, 115);
}

.date:hover {
    border: 1px solid rgb(4, 56, 115);
  border-radius: 10px;
  color: rgb(4, 56, 115);
}

.nav_admin_button:hover {
    background-color: rgb(4, 56, 115);
    color: white;
}


.card_admin_nav {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 80px;
    margin-bottom: 80px;
   
}

.logo_card_admin {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 5px solid rgb(4, 56, 115);
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: -30px ;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 0 #ccc;
    background-color: rgb(4, 56, 115);
}
}
   

 </style>
 