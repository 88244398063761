<template>

<div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showModal }">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">Ajouter un Service</h5>
          <button type="button" class="close" @click="showModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
    <form @submit.prevent="addService"> 
                <div class="form-group">
                <label for="eventTitle">Nom</label>
                <input type="text" class="form-control" id="eventTitle" v-model="nom" required>
                </div>
                <div class="form-group mt-3"> 
                <label for="eventDescription">prix</label>
                <input type="number" class="form-control" id="eventStartDate" v-model="prix" required>

                </div>
                

                <div class="form-group mt-3">
                <label for="eventStartDate">temps</label> 
                <input type="number" class="form-control" id="eventStartDate" v-model="temps" required>
                </div>


                <button style="background-color: rgb(4, 56, 115); color: white;" type="submit" class="btn btn-primary mt-3">Ajouter Service</button>

                



    </form>

        </div>
      </div>
    </div>
  </div>

  <div :class="{ 'blurred': showModal}" >

 
    <!--Header  -->
    <div class=" mt-5">
        <div class="d-flex justify-content-between">
    <button @click="showModal = true" class="button_patient">
        Add Service
    </button>

    

    <input style="margin-right: 10px;" class="search_input" type="text" placeholder="Search Service" />
   </div>
    </div>

    <!--Service Table -->

    <div class="mt-5">
      <table class="custom-table">
        <thead>
          <tr style="text-align: center; vertical-align: middle;">
            <th class="gray-header">Service</th>
            <th class="gray-header">Temps</th>
            <th class="gray-header">Tarif</th>
            <th class="gray-header"></th>
          </tr>
        </thead>
        <tbody>
          <tr style="height: 50px; border-bottom: 1px solid #ccc;" v-for="(service,) in services" :key="service.id">
            <td>
              <template v-if="!service.editing">{{ service.nom }}</template>
              <template v-else>
                <input type="text" v-model="service.nom" />
              </template>
            </td>
            <td>
              <template v-if="!service.editing">{{ service.temps }} min</template>
              <template v-else>
                <input type="number" v-model="service.temps" />
              </template>
            </td>
            <td>
              <template v-if="!service.editing">{{ service.prix }}€</template>
              <template v-else>
                <input type="number" v-model="service.prix" />
              </template>
            </td>
            <td>
              <a style="margin-right: 20px;" @click="toggleEdit(service)">
                <template v-if="!service.editing">Modifier</template>
                <template v-else>Enregistrer</template>
              </a>
              <a @click="deleteService(service)" style="color: red;">Supprimer</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      services: [],
      selectedServices: [],
      showModal: false,
      id: 2,
      nom: '',
      prix: '',
      temps: ''
    };
  },
  methods: {
    addService() {
      // Create a new service object
      const newService = {
        id: this.id,
        nom: this.nom,
        prix: this.prix,
        temps: this.temps,
        editing: false // Add an editing flag to track if the service is being edited
      };

      // Push the new service object into the services array
      this.services.push(newService);

      // Clear input fields after adding service
      this.id++; // Increment id for the next service
      this.nom = '';
      this.prix = '';
      this.temps = '';

      // Log the updated services array
      console.log(this.services);
      this.postService()
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedServices = this.services;
      } else {
        this.selectedServices = [];
      }
    },
    toggleSelection(service) {
      if (this.selectedServices.includes(service)) {
        this.selectedServices = this.selectedServices.filter(s => s !== service);
      } else {
        this.selectedServices.push(service);
      }
    },
    toggleEdit(service) {
      // Toggle the editing flag for the selected service
      service.editing = !service.editing;
      // If editing is done, you can update the service details through an API call here.
      this.postService()
    },
    deleteService(service) {
  console.log('Delete service', service);
  const index = this.services.findIndex(s => s === service); // Find the index of the service
  if (index !== -1) {
    this.services.splice(index, 1); // Remove the service from the array
  }
  this.postService()
},

    fetchDoctorinfo() {
      // fetch doctor info
      const token = localStorage.getItem('token');

      axios.get('http://13.49.196.22/profile/doctor', {
          headers: {
            Authorization: `${token}`
          }
        })
        .then(response => {
          console.log(response.data);
          this.services = response.data.service;
        })
        .catch(error => {
          console.log(error);
        });
    },

    postService(){
      axios.put(`http://13.49.196.22/profile/doctor`, {
        service : this.services
    }, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(response => {
        console.log('Event updated successfully', response);
        this.fetchDoctorinfo();
        this.showModal = false;
    })
    .catch(error => {
        console.error('There was an error!', error);
    });
    },



  },
  mounted() {
    this.fetchDoctorinfo();
  }
};
</script>


<style scoped>
.custom-table {
  width: 90%;
  border-collapse: collapse;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  font-size: 15px;
}

.gray-header {
  background-color: #f1f1f1;
  color: black;
  padding: 10px;
  font-weight: 300;
}

.gray-header input[type="checkbox"] {
  margin: 0;
}

.fast_actions {
  margin-top: 20px;
  padding: 10px;
  color: white;
  background-color: #4E6DCE;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;  
}

.fast_actions_inside {
  margin-left: 10px;
  margin-right: 10px;
  border-right: 1px solid white;
}

.button_patient {
  background-color: white;
  color: #4E6DCE;
  border: 1px solid #4E6DCE;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin-right: 10px;

}

.search_input {
  border: 1px solid #4E6DCE;
  border-radius: 5px;
  padding: 5px;
  width: 200px;
}

.add_patient {
  background-color: #4E6DCE;
  color: white;
  border: 1px solid #4E6DCE;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.show_form_filter {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 80%;
}

  .show_form_filter input {
      border: 1px solid #4E6DCE;
      border-radius: 5px;
      padding: 5px;
      margin-top: 10px;
  }

  .show_form_filter select {
      border: 1px solid #4E6DCE;
      border-radius: 5px;
      padding: 5px;
      margin-top: 10px;
  }

  .show_form_filter label {
      font-weight: 400;
  }

  .show_form_filter button {
      background-color: #4E6DCE;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
      margin-top: 10px;
  }

  .show_form_content {
      display: flex;
      justify-content: space-between;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 10px;
      margin-top: 20px;

  }
  .blurred {
      filter: blur(5px);
      pointer-events: none; /* Empêcher les interactions sur le fond flou */
      overflow: hidden;
    }
</style>
```