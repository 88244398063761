<template>

       <div class="row">
              <div  style="height: 700px;  " class="col-md-6 mt-2">
                     <h1 style="font-family: Apple Chancery;
font-weight: 600;"> Prenez Rendez-vous </h1>
                     <div style="padding: 40px" class="progress-container">
                            <div class="circle" :class="{ active: activeCircle === 1 }">
                                   <span style="margin-top: -50px;">Service</span>
                            </div>
                            <div class="line"></div>
                            <div class="circle" :class="{ active: activeCircle === 2 }">
                                   <span style="margin-top: -50px;">Time</span>
                            </div>
                            <div class="line"></div>
                            <div class="circle" :class="{ active: activeCircle === 3 }">
                                   <span style="margin-top: -50px;">Recapitulatif</span>
                            </div>
                            <div class="line"></div>
                            <div class="circle" :class="{ active: activeCircle === 4 }">
                                   <span style="margin-top: -50px;">Confirmation</span>
                            </div>
                     </div>



                     <div v-if="activeCircle === 1">
                            <span>
                                   Docteur :
                            </span>
                            <p style="margin-bottom: 40px;" class="form-control form3">
                                   DR {{ doctor.nom }} {{
                                   doctor.prenom }}
                            </p>
                            <span style="margin-top: 20px;">
                                   Selectionner un Service :
                            </span>
                            <select style="padding: 10px 20px; margin-bottom: 40px;" v-model="selectedService"
                                   class="form-control form3">
                                   <option disabled value="">Sélectionnez un Service</option>
                                   <option v-for="service in doctor.service" :key="service.id" :value="service.nom">{{
                                   service.nom }}</option>
                            </select>
                            <div class="d-flex justify-content-between">
                                   <button class="button_confirm">
                                          Retour a la Recherche
                                   </button>
                                   <button v-if="selectedService != ''" class="button_confirm" @click="nextStep">
                                          Suivant
                                   </button>
                            </div>
                     </div>

                     <div v-if="activeCircle === 2">
                            <input style="justify-content: center; width: 90%; border: 1px solid rgb(4, 56, 115) ;" type="date" v-model="selectedDate"
                                   @change="handleDateChange" class="form3">
                            <div class="mt-5">
                                   <div v-if="timeslots.length === 0">
                                          Aucun RDV Disponible Pour le {{ selectedDate }}
                                   </div>
                                   <div v-else>
                                          <div class="timeslot-container d-inline-flex"
                                                 v-for="(slot, index) in timeslots" :key="index">
                                                 <button class="form3" @click="selectTime(slot.start)">{{ slot.start
                                                        }}</button>
                                          </div>
                                   </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                   <button class="button_confirm" @click="prevStep">
                                          Retour
                                   </button>
                                   <button v-if="selectedHour != ''" class="button_confirm" @click="nextStep">
                                          Suivant
                                   </button>
                            </div>
                     </div>

                     <div v-if="activeCircle === 3">


                            <span>
                                   Service Selectionner :
                            </span>
                            <p class="form3 mt-4">
                                   {{ this.selectedService }}
                            </p>
                            <span>
                                   Date Selectionner :
                            </span>
                            <p class="form3 mt-4">
                                   {{ this.selectedDate }}

                            </p>
                            <span>
                                   Heure Selectionner :
                            </span>
                            <p class="form3 mt-4">
                                   {{ this.selectedHour }}
                            </p>

                            <div class="d-flex justify-content-between">
                                   <button class="button_confirm" @click="prevStep">
                                          Retour
                                   </button>
                                   <button class="button_confirm" @click="addEvent">
                                          Confirmation RDV
                                   </button>
                            </div>


                     </div>

                     <div v-if="activeCircle === 4">
                            <div style="margin-top: 40px;">
                                   <h4 style="font-weight: 600;"> Rendez-vous Pris Avec Succes Pour le <br> {{
                                          this.selectedDate }} a {{ this.selectedHour }}h </h4>

                            </div>
                            <div style="margin-top: 40px;">
                                   <span>
                                          Un Mail de Confirmation Vous a été Envoyer
                                   </span>
                            </div>

                            <div style="margin-top: 40px;">
                                   <RouterLink class="button_confirm" to="/">
                                          Retour a L'acceuil
                                   </RouterLink>
                            </div>





                     </div>









              </div>
              <div style="height: 700px;" class="col-md-6">
                     <img class="no-display" src="https://png.pngtree.com/thumb_back/fw800/background/20230718/pngtree-contemporary-doctor-s-consultation-room-a-3d-rendering-of-a-professional-image_3900509.jpg"
                            style="width: 100%; height: 100%; object-fit: cover;">
              </div>
       </div>




</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Lisbon');

export default {
       data() {
              return {
                     timeslots: [],
                     selectedDate: new Date().toISOString().substr(0, 10),
                     doctor: {},
                     selectedService: '',
                     activeCircle: 1,
                     selectedHour: '',
                     selectedDebut:'',
                     selectedEnd:'',
                     color:'#a0e169',
                     serviceDuration:''

              };
       },

       methods: {

              isconnected() {
                     // Vérifier si un token est présent dans le stockage local
                     const token = localStorage.getItem('token');

                     if (token) {
                            // L'utilisateur est connecté, rien à faire
                            return true; // Retourne true pour indiquer que l'utilisateur est connecté
                     } else {
                            // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
                            this.$router.push('/login'); // Utilisez $router pour accéder au routeur et naviguer vers /login
                            return false; // Retourne false pour indiquer que l'utilisateur n'est pas connecté
                     }
              },

              nextStep() {
                     // Vérifier si activeCircle est inférieur à 3 avant d'incrémenter
                     if (this.activeCircle < 4) {
                            this.activeCircle++;
                     }
                     this.fetchTimeSlots();
              },
              prevStep() {
                     // Vérifier si activeCircle est supérieur à 1 avant de décrémenter
                     if (this.activeCircle > 1) {
                            this.activeCircle--;
                     }
              },
              // Méthode pour récupérer les informations du médecin
              fetchDoctorInfo() {
                     const id = this.$route.params.id;
                     axios.get('http://13.49.196.22/doctors/' + id)
                            .then(response => {
                                   this.doctor = response.data;
                                   console.log(this.doctor);
                                   // Pas besoin de récupérer les créneaux horaires ici, cela sera fait lors du changement de date
                            })
                            .catch(error => {
                                   console.log(error);
                            });
              },

              // Méthode appelée à chaque changement de date
              handleDateChange(event) {
                     // Récupérer la date sélectionnée depuis l'événement
                     this.selectedDate = event.target.value;
                     // Appeler la méthode pour récupérer les créneaux horaires avec la nouvelle date sélectionnée
                     this.fetchTimeSlots();
              },

              selectTime(time) {
      // Extraire les heures et les minutes du temps sélectionné
      const [hours, minutes] = time.split(':').map(Number);
      // Créer un nouvel objet Moment pour la date sélectionnée
      const selectedMoment = moment(this.selectedDate);
      // Définir les heures et les minutes
      selectedMoment.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });

      // Définir le fuseau horaire portugais
      selectedMoment.tz('Europe/Lisbon');

      // Obtenir la date et l'heure sélectionnées au format ISO
      const selectedDateTimeISO = selectedMoment.toISOString();
      this.selectedHour = time;
      console.log('Date et heure sélectionnées au format ISO : ', selectedDateTimeISO);
      this.selectedDebut = selectedDateTimeISO;

    // Récupérer l'objet complet du service sélectionné à partir de la base de données
    const selectedService = this.doctor.service.find(service => service.nom === this.selectedService);

    if (!selectedService) {
        console.error("Service non trouvé.");
        return;
    }

    // Récupérer la durée du service à partir de l'objet complet du service
    const serviceDuration = selectedService.temps;
    this.serviceDuration = serviceDuration;

    // Calculer l'heure de fin de la prestation
    const endTime = this.calculateEndTime(selectedMoment, serviceDuration);
    this.selectedEnd = endTime;
    console.log('Heure de fin de la prestation : ', endTime);
},

calculateEndTime(startTime, serviceDuration) {
    // Convertir la durée du service en minutes
    const serviceDurationMinutes = parseInt(serviceDuration);
    // Calculer l'heure de fin en ajoutant la durée du service en minutes à l'heure de début
    const endTime = new Date(startTime.getTime() + serviceDurationMinutes * 60000); // 60000 ms = 1 minute
    // Formater l'heure de fin au format ISO

    return endTime.toISOString();
}
,

              // Méthode pour récupérer les créneaux horaires en fonction de la date sélectionnée
              fetchTimeSlots() {
    const doctorID = this.$route.params.id;
    const date = this.selectedDate;
    const selectedService = this.doctor.service.find(service => service.nom === this.selectedService);

    if (!selectedService) {
        console.error("Service non trouvé.");
        return;
    }

    // Récupérer la durée du service à partir de l'objet complet du service
    const serviceDuration = selectedService.temps;

    axios.get('http://13.49.196.22/timeslots/' + doctorID + '?date=' + date + '&duration=' + serviceDuration )
        .then(response => {
            // Convertir les timeslots au fuseau horaire du Portugal avant de les afficher
            this.timeslots = response.data.map(slot => {
                const start = moment(slot.start).tz('Europe/Lisbon').format('HH:mm'); // Convertit au fuseau horaire du Portugal
                return {
                    start: start,
                };
            });
        })
        .catch(error => {
            console.log(error);
        });
},

              addEvent() {
    const medecin = this.$route.params.id;
    const debut = this.selectedDebut;
    const fin = this.selectedEnd;
    const description = this.selectedService;
    const client = localStorage.getItem('nom') + '' + localStorage.getItem('prenom') ;
    const color = this.color;
    const title = localStorage.getItem('nom');
    const patientId = localStorage.getItem('patientId');
   

    axios.post('http://13.49.196.22/events/', {
        medecin,
        debut,
        fin,
        description,
        client,
        color,
        title,
        patientId
        
    }, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(response => {
        console.log('Event added successfully', response);
        this.nextStep()
       
    })
    .catch(error => {
        console.error('There was an error!', error);
    });
}
,
       },

       computed: {
              // Ajoutez vos computed properties ici si nécessaire
       },

       mounted() {
              this.fetchDoctorInfo();
              this.isconnected();
       },
};
</script>

<style>
.border3 {
       border: 1px solid #4E6DCE !important;
       border-radius: 20px 20px 0px 0px;
       padding: 10px;
       width: 100%;
       justify-content: space-between;
       align-items: center;
}

.button_date {
       border: none;
       background-color: #4E6DCE;
       margin: 6px;
       color: white;
       border-radius: 50%;
       height: 60px;
       width: 60px;
       padding: 10px;
       display: flex;
       justify-content: center;
       align-items: center;
       font-size: small;
       font-weight: 500;
       cursor: pointer;
}

.form3 {
       border: none;
       background-color: #ffff;
       margin: 6px;
       color: black;
       border-radius: 5px;
       box-shadow: 0 0 5px 0 #435f7133;
       padding: 10px 20px;
       cursor: pointer;
}

.form3:hover {
       background-color: #4E6DCE;
       color: #ffff;
       font-weight: 500;
}

.form3::selection {
       background-color: #4e6dce;
       color: #ffff;
}

.logo7 {
       width: 50px;
       height: 50px;
       border-radius: 50%;
       margin: 10px;
}

.button_confirm {
       border: 1px solid #4E6DCE;
       background-color: #ffff;
       margin: 6px;
       color: #4E6DCE;
       border-radius: 20px;
       padding: 10px 20px;
       cursor: pointer;
       font-weight: 500;
       font-size: 15px;
       margin-bottom: 20px;
       margin-right: 20px;
       margin-top: 20px;
}

.button_confirm:hover {
       background-color: #4E6DCE;
       color: #ffff;
}

.button-timeslot2 {
       border: 1px solid #4E6DCE;
       background-color: #ffff;
       margin: 6px;
       color: #4E6DCE;
       border-radius: 20px;
       padding: 1px 20px;
       cursor: pointer;
       font-weight: 500;
       font-size: 15px;
       margin-bottom: 20px;
       margin-right: 20px;
       margin-top: 20px;
}

.button-timeslot2:hover {
       background-color: #4E6DCE;
       color: #ffff;
}

.card-container {
       display: flex;
       overflow-x: auto;
       /* Ajoute un défilement horizontal si nécessaire */
       -webkit-overflow-scrolling: touch;
       /* Pour améliorer le défilement sur les appareils mobiles */
       max-width: 100%;
       /* Pour que le conteneur s'ajuste à la largeur du contenu */
       margin-bottom: 20px;
       /* Ajoute un espacement en bas */
}

.timeslot-container {
       flex: 0 0 auto;
       /* Empêche le redimensionnement automatique des conteneurs */
       margin-right: 10px;
       /* Ajoute un espacement entre les boutons */
}

.progress-container {
       display: flex;
       justify-content: space-between;
       align-items: center;
       width: 80%;
       margin: 50px auto;
}

.circle {
       position: relative;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       width: 20px;
       height: 20px;
       border: 2px solid #ccc;
       border-radius: 50%;
       background-color: #fff;
       z-index: 1;
}

.circle.active {
       border-color: #007bff;
}

.line {
       flex: 1;
       height: 2px;
       background-color: #ccc;
       z-index: 0;
}

.details {
       margin-top: 5px;
       font-size: 12px;
       color: #666;
}
@media screen and (max-width: 768px) {
    .progress-container {
       
        width: 90%;

    }

    .circle {
        width: 15px;
        height: 15px;
        font-size: small;
    }

    .line {
        height: 1px;
    }

    /* Adjust other styles as needed */
}

/* Media query for even smaller screens, like phones */
@media screen and (max-width: 480px) {
    .progress-container {
        width: 95%;
    }

    .circle {
        width: 12px;
        height: 12px;
        font-size: x-small;
    }

    .line {
        height: 1px;
    }

    /* Adjust other styles as needed */
}
</style>
