<template>

            <div class="container mb-5">

                <form id="search" class="searchbar" @submit.prevent="submitSearch">
                    <input style="border-right: 1px solid #ccc;" type="text" placeholder="Spécialiste"
                        class="input-specialiste" v-model="specialisteQuery">
                    <div class="search-container">
                        <input ref="addressInput" @input="searchAddress" type="text" placeholder="Lieux"
                            class="input-lieux" v-model="address" @focus="showResults">
                        <ul v-show="showSearchResults" class="search-results">
                            <li v-for="result in searchResults" :key="result.id" @click="selectAddress(result)">{{
        result.place_name }}</li>
                        </ul>
                    </div>
                    <div class="suggestions" v-show="showSuggestions">
                        <ul>
                            <li v-for="suggestion in filteredWords" :key="suggestion"
                                @click="selectSuggestion(suggestion)">{{ suggestion }}</li>
                        </ul>
                    </div>
                    <button type="submit" class="search-button">Recherche</button>
                </form>





            </div>

</template>


<script>
import MapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

export default {
    data() {
        return {
            words: [
                'Médico de Família',
                'Cirurgião',
                'Dentista',
                'Enfermeiro(a)',
                'Farmacêutico(a)',
                'Fonoaudiólogo(a)',
                'Psicólogo(a)',
                'Fisioterapeuta',
                'Obstetriz',
                'Oftalmologista',
                'Pediatra',
                'Ginecologista e Obstetra',
                'Radiologista',
                'Anestesista',
                'Cardiologista',
                'Dermatologista',
                'Gastroenterologista',
                'Neurologista',
                'Oncologista',
                'Pneumologista',
                'Reumatologista',
                'Urologista',
                'Quiropraxista',
                'Osteopata',
                'Podólogo'
            ],
            currentIndex: 0,
            typedText: '',
            specialisteQuery: '',
            specialisteSuggestions: [],
            lieuxQuery: '',
            lieuxAutocomplete: [],
            address: '',
            searchResults: [],
            faqs: [
                {
                    question: "Comment créer un compte?",
                    answer:
                        "Pour créer un compte, cliquez sur le bouton 'S'inscrire' en haut à droite de la page d'accueil, puis suivez les instructions pour entrer vos informations personnelles et créer un mot de passe.",
                    show: false,
                },
                {
                    question: "Comment rechercher un médecin?",
                    answer:
                        "Utilisez la barre de recherche pour entrer le nom, la spécialité ou la localisation du médecin que vous cherchez. Vous pouvez également filtrer les résultats selon vos préférences.",
                    show: false,
                },
                {
                    question: "Quels sont les modes de paiement acceptés?",
                    answer:
                        "Nous acceptons les paiements par carte de crédit, carte de débit et PayPal. Toutes les transactions sont sécurisées.",
                    show: false,
                },
                {
                    question: "Comment annuler ou reprogrammer un rendez-vous?",
                    answer:
                        "Pour annuler ou reprogrammer un rendez-vous, accédez à la section 'Mes rendez-vous', sélectionnez le rendez-vous que vous souhaitez modifier, et choisissez l'option d'annulation ou de reprogrammation.",
                    show: false,
                },
            ],
            testimonials: [
                {
                    image: 'https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360',
                    name: 'Enzo Viana',
                    title: 'Médecin Généraliste',
                    feedback: 'Whitepate est conçu comme un outil de collaboration pour les entreprises, offrant une solution complète de gestion de projet.'
                },
                {
                    image: 'https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360',
                    name: 'Marie Dupont',
                    title: 'Cardiologue',
                    feedback: 'Whitepate a grandement amélioré notre efficacité et la communication au sein de notre équipe.'
                },
                {
                    image: 'https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360',
                    name: 'Paul Martin',
                    title: 'Chirurgien',
                    feedback: 'Grâce à Whitepate, nous avons pu mieux organiser nos projets et respecter les délais.'
                }
            ],
            selectedAddress: null,
            showSuggestions: false,
            showSearchResults: false,
            selectedLatitude: null,
            selectedLongitude: null
        };
    },
    mounted() {
        this.typeNextWord();
    },
    computed: {
        filteredWords() {
            return this.words.filter(word => word.toLowerCase().includes(this.specialisteQuery.toLowerCase()));
        }
    },
    methods: {
        showResults() {
            this.showSearchResults = true;
        },
        selectAddress(result) {
            this.address = result.place_name;
            this.selectedAddress = result;
            if (result.center && result.center.length >= 2) {
                this.selectedLatitude = result.center[1]; // Latitude de l'adresse sélectionnée
                this.selectedLongitude = result.center[0]; // Longitude de l'adresse sélectionnée
            }
            this.showSearchResults = false; // Cacher la liste après avoir sélectionné un lieu
        },

        selectSuggestion(suggestion) {
            this.specialisteQuery = suggestion;
            this.showSuggestions = false; // Cache les suggestions une fois que l'utilisateur en a sélectionné une
        },

        async submitSearch() {
            if (!this.selectedAddress && this.searchResults.length > 0) {
                // Si aucune adresse n'est sélectionnée, sélectionner la première suggestion
                this.selectAddress(this.searchResults[0]);
            }

            // Validation des données
            if (!this.selectedAddress || !this.selectedLatitude || !this.selectedLongitude) {
                console.error('Veuillez sélectionner une adresse valide.');
                return;
            }

            // Construction de l'URL avec les champs du formulaire et les coordonnées géographiques
            const query = `?specialite=${encodeURIComponent(this.specialisteQuery)}&ville=${encodeURIComponent(this.lieuxQuery)}&latitude=${encodeURIComponent(this.selectedLatitude)}&longitude=${encodeURIComponent(this.selectedLongitude)}`;

            // Redirection vers /search avec les paramètres de requête
            window.location.href = `/search${query}`;
        },

        async searchAddress() {
            const mapboxClient = MapboxGeocoding({ accessToken: 'pk.eyJ1IjoiZW56b3YiLCJhIjoiY2x3dDhuZGtxMDE3dDJqc2F0dHRnMGhocyJ9.w4ghuWWoBjSzHyHKHx8R9A' }); // Remplacez par votre token Mapbox

            const response = await mapboxClient.forwardGeocode({
                query: this.address,
                countries: ['FR'],
                limit: 5, // Limite le nombre de résultats
            }).send();

            if (response && response.body && response.body.features) {
                this.searchResults = response.body.features;
            }
        },

        typeNextWord() {
            const nextWord = this.words[this.currentIndex];
            this.typedText = '';

            let i = 0;
            const interval = setInterval(() => {
                this.typedText += nextWord[i];
                i++;
                if (i === nextWord.length) {
                    clearInterval(interval);
                    setTimeout(() => {
                        this.deleteWord(nextWord);
                    }, 1000);
                }
            }, 100);
        },
        deleteWord(word) {
            let i = word.length - 1;
            const interval = setInterval(() => {
                this.typedText = this.typedText.slice(0, -1);
                i--;
                if (i === -1) {
                    clearInterval(interval);
                    setTimeout(() => {
                        this.currentIndex = (this.currentIndex + 1) % this.words.length;
                        this.typeNextWord();
                    }, 1000);
                }
            }, 100);
        },
        fetchSpecialisteSuggestions() {
            // Votre logique pour récupérer les suggestions de spécialistes
            // Par exemple, vous pouvez utiliser une requête API ou une liste prédéfinie

            // Simulons des données de suggestions de spécialistes (remplacez ceci par votre propre logique)
            const suggestions = ['Cardiologue', 'Dermatologue', 'Ophtalmologiste', 'Gynécologue', 'Pédiatre'];

            // Filtrer les suggestions en fonction de la requête de l'utilisateur
            const filteredSuggestions = suggestions.filter(suggestion =>
                suggestion.toLowerCase().includes(this.specialisteQuery.toLowerCase())
            );

            // Mettre à jour this.specialisteSuggestions avec les résultats filtrés
            this.specialisteSuggestions = filteredSuggestions;
        },

        fetchLieuxAutocomplete() {
            // Votre logique pour récupérer les autocomplétions de lieux
            // Par exemple, vous pouvez utiliser une requête API ou une liste prédéfinie

            // Simulons des données d'autocomplétion de lieux (remplacez ceci par votre propre logique)
            const lieux = ['Paris', 'Marseille', 'Lyon', 'Toulouse', 'Nice', 'Nantes', 'Strasbourg', 'Montpellier'];

            // Filtrer les lieux en fonction de la requête de l'utilisateur
            const filteredLieux = lieux.filter(lieu =>
                lieu.toLowerCase().includes(this.lieuxQuery.toLowerCase())
            );

            // Mettre à jour this.lieuxAutocomplete avec les résultats filtrés
            this.lieuxAutocomplete = filteredLieux;
        },
        selectSpecialisteSuggestion(suggestion) {
            this.specialisteQuery = suggestion;
            this.specialisteSuggestions = []; // Efface les suggestions une fois que l'utilisateur en a choisi une
        },
        selectLieuxSuggestion(suggestion) {
            this.lieuxQuery = suggestion;
            this.lieuxAutocomplete = []; // Efface les autocomplétions une fois que l'utilisateur en a choisi une
        },
        toggleFaq(index) {
            this.faqs[index].show = !this.faqs[index].show;
        },
    }
};
</script>

<style>
.active {
    background-color: transparent;
}

.carousel-item {
    background-color: transparent;
}

.testimonial-card {
    width: 700px;
    /* Largeur plus grande */
    height: 100px;
    /* Hauteur moins grande */
    background-color: #FFFFFF;
    border: none !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.testimonial-card .card-img-top {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin: 20px auto 0;
}


.searchbar {
    border-radius: 15px;
    box-shadow: 0 0 20px 0 #435f7133;
    display: flex;
    height: 60px;
    min-width: 600px;
    width: 90% !important;
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    padding: 7px;
}

.background1 {
    background-color: rgb(4, 56, 115);
    height: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 2em;
    margin: 0;
    padding: 0;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    text-align: left;
}

.input-specialiste,
.input-lieux {
    flex: 1;
    border: none;
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
    width: 90%;
}

.search-button {
    border: none;
    background-color: rgb(4, 56, 115);

    color: white;
    border-radius: 2px 15px 15px 2px;
    padding: 10px;
    cursor: pointer;
}

.card {
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #435f7133;
}

.number {
    font-size: 2em;
    font-weight: bold;
    color: white;
}

.button-nav {
    background-color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: small;
    color: #2c3e50;

}

.button-nav:hover {
    color: #00b8ff;
}

li {
    list-style: none;
    margin: 10px;

    font-weight: bold;
    color: white;
}

.card2 {
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #435f7133;
    border: none;
    padding: 10px;
}

.card_categorie {
    position: relative;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #435f7133;
    height: 300px;
    border: none;
    cursor: pointer;
}

.card_categorie::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Couleur de fond semi-transparente */
    border-radius: 20px;
}

.card_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    text-align: center;
    z-index: 1;
    font-weight: bold;
}

.button-article {
    background-color: #4E6DCE;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: small;
    color: white;
}


.point {
    width: 6px;
    height: 6px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 15px;
}

.svg_how {
    height: 50px;
    width: 50px;
    box-shadow: 0 0 20px 0 #435f7133;
    border-radius: 10px;
    padding: 13px;
    margin-left: auto;
    margin-right: auto;
}

.read_more_button {
    background-color: rgb(4, 56, 115);
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: small;
    color: white;
}

.suggestions,
.autocompletions {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    width: calc(100% - 22px);
    /* Prend la largeur du parent moins les marges */
}

.suggestions ul,
.autocompletions ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.suggestions .suggestion-item,
.autocompletions .suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestions .suggestion-item:hover,
.autocompletions .suggestion-item:hover {
    background-color: #f2f2f2;
}

.search-container {
    position: relative;
    display: inline-block;
    text-align: left;
}

.search-results {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-radius: 15px;
    box-shadow: 0 0 20px 0 #435f7133;
    background-color: white;
    position: absolute;
    width: calc(100% - 20px);
    /* Largeur égale à la largeur du champ "Lieux" */
    max-height: 200px;
    /* Limitez la hauteur de la liste pour ajouter un défilement si nécessaire */
    overflow-y: auto;
    /* Ajoutez un défilement vertical si la liste dépasse la hauteur définie */
    top: calc(100% + 5px);
    /* Placez la liste juste en dessous du champ "Lieux" */
    left: 0;
    max-height: 140px;
}

.search-results li {
    padding: 10px;
    cursor: pointer;
}

.search-results li:hover {
    background-color: #f0f0f0;
}


p {
    line-height: 1.4em;
    color: #9e9e9e;
}

.faq-title {
    font-size: 2em;
    margin: 0.4em 0;
}

div.seperator {
    width: 7.5em;
    background-color: #4F9CF9;
    height: 0.17em;
    margin-left: -1.8em;
}

.faq-list>div {
    border-bottom: 0.07em solid #ededed;
    padding: 1em 0em;
}

.faq-list>div:last-child {
    border: unset;
}

details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}

summary {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease;
}

summary:hover {
    color: #4F9CF9;
}

details[open] summary~* {
    animation: sweep .5s ease-in-out;
}

@keyframes sweep {
    0% {
        opacity: 0;
        margin-left: -10px
    }

    100% {
        opacity: 1;
        margin-left: 55px
    }
}

details[open] summary {
    color: #4F9CF9;
}

details[open] p {
    border-left: 2px solid #4F9CF9;
    margin-left: 55px;
    padding-left: 25px;
    opacity: 100;
    transition: all 3s ease;
}

details[open] summary:after {
    content: "-";
    font-size: 3.2em;
    margin: -33px 0.35em 0 0;
    font-weight: 200;
}


.faq-list {
    margin: 1em auto;
    padding: 2em 0;
}

summary::-webkit-details-marker {
    display: none;
}

summary:after {
    background: transparent;
    border-radius: 0.3em;
    content: "+";
    color: #4F9CF9;
    float: left;
    font-size: 1.8em;
    font-weight: bold;
    margin: -0.3em 0.65em 0 0;
    padding: 0;
    text-align: center;
    width: 25px;
}


@media only screen and (max-width: 768px) {
    .search-results{
        min-width: 289px;
    }
    summary {
        font-size: 14px
    }

    .card-container {
        padding: 20px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 25px;
    }

    h4 {
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }

    .card-padding {
        padding: 30px 20px;
    }

    .highlight {
        color: #4F9CF9;
    }

    .faq-container {
        margin-top: 50px;
    }

    .faq-item {
        margin-bottom: 15px;
    }

    .background1 {
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .number {
        font-size: 20px;
        font-weight: bold;
        color: white;
        margin-top: 30px;
    }

    h6 {
        font-size: 13px !important;
    }

    .title {
        font-size: 1.5em;
        text-align: center;
    }

    .svg_how {
        height: 40px;
        width: 40px;
        padding: 10px;
        margin: 10px auto;
    }

    .point {
        margin: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    .block {
        display: block !important;

    }

    .col-md-2 {
        text-align: center;
    }

    .col-md-5 img {
        height: auto;
        width: 100%;
        margin-top: 50px;
    }

    .searchbar {
        height: auto;
        flex-direction: column;
        padding: 10px;
        min-width: 100px;
    }

    .input-specialiste,
    .input-lieux {
        margin-bottom: 10px;
        width: 100%;
    }

    .container.mb-5 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .search-button {
        border: none;
        background-color: rgb(4, 56, 115);

        color: white;
        border-radius: 15px;
        padding: 10px;
        cursor: pointer;
    }

    .img_phone {
        margin-top: 50px;
        height: 200px;
        width: auto;
    }

    .no-display {
        display: none;
    }

    .accordion-item {
        margin-bottom: 10px;
    }

    .col-md-7,
    .col-md-5 {
        width: 100%;
    }

    .card2 img {
        height: auto;
        width: 100%;
    }

    .d-flex img {
        height: auto;
        width: 50%;
        margin-right: 10px;
    }

    .button-article {
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>