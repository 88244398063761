<template>
<nav v-if="!isAdminArea()" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <!-- Nom de l'entreprise à gauche -->
        <a class="navbar-brand" href="/" style="color: rgb(4, 56, 115); font-weight: bold;">MEDICO.PT</a>

        <!-- Toggle button for responsive navbar -->
        <button class="navbar-toggler" type="button" @click="toggleNavbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Contenu de la navbar -->
        <div class="collapse navbar-collapse" :class="{'show': isNavbarOpen}" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <!-- Liens, menus, etc. peuvent être ajoutés ici -->
            </ul>
            
            <!-- Bouton "Vous êtes praticien" et "Se connecter" à droite -->
            <div v-if="isPatientLoggedIn()" class="dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="color: rgb(4, 56, 115);">
                    <svg style="height: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg> 
                    {{ nom }} {{ prenom }}
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                    <li><a class="dropdown-item" href="/dashboard">Dashboard</a></li>
                    <li><a class="dropdown-item" href="#" @click="logout">Logout</a></li>
                </ul>
            </div>
            
            <!-- Si le token ou le type d'utilisateur ne sont pas présents, affiche le bouton de connexion -->
            <div v-else class="d-flex justify-content-between">
                <RouterLink  class=" button_pratcitien" to="/practicien">Vous êtes praticien ?</RouterLink>
                <RouterLink class=" button_se_connecter " to="/login">Se connecter</RouterLink>
            </div>
        </div>
    </div>
</nav>



<div v-else class="row">
  <div class="col-md-2">
    <nav  style="height: 100%; font-size: 14px;" class=" d-flex align-items-start flex-column nav_admin">
                <div style="align-self: center;" class="d-flex justify-content-center mt-2 mb-5">
                   <h3 style="color: white; font-weight: 900;" >
                   MEDICO.PT
                   </h3>
                </div>
                <div class="d-flex align-items-center mt-4">
    <svg style="height: 20px; width: auto; margin-right: 20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="#ffffff" d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/>
    </svg>   
    <router-link style="color:white" to="/admin" class="ml-2">Dashboard</router-link>
</div>


<div class="d-flex align-items-center mt-4">
    <svg style="height: 20px; width: auto; margin-right: 20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"/></svg>   
    <router-link  style="color:white" to="/admin/planning" class="ml-2">Planning</router-link>
</div>

<div class="d-flex align-items-center mt-4">
   <svg style="height: 20px; width: auto; margin-right: 20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/></svg>  
    <router-link style="color:white" to="/admin/patient" class="ml-2">Patient</router-link>
</div>

<div class="d-flex align-items-center mt-4">
    <svg style="height: 20px; width: auto; margin-right: 20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      
      <path fill="#ffffff" d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>
    <router-link style="color:white" to="/admin/service" class="ml-2">Service</router-link>
</div>

<div class="card_admin_nav ">
    <div class="logo_card_admin">
<svg tyle="height: 20px; width: auto; " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>    </div>
    <h5>
        Un Probleme ?
    </h5>
    <p>
        Contactez notre support technique pour obtenir de l'aide. 
    </p>
</div>


<div class="d-flex align-items-center mt-4">
    <svg style="height: 20px; width: auto; margin-right: 20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>  
    <router-link style="color:white" to="/admin/settings" class="ml-2">Settings</router-link>
</div>

<div class="d-flex align-items-center mt-4">
   <svg style="height: 20px; width: auto; margin-right: 20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"/></svg>
    <router-link style="color:white" to="/" @click="logout" class="ml-2">Logout</router-link>
</div>



                
             </nav>
  </div>
  <div  class="col-md-10">
    <router-view/>
  </div>
</div>

<router-view  v-if="!isAdminArea()" />

  <footer style="background-color: rgb(4, 56, 115); color: white; padding: 20px; margin-top: 100px;" v-if="!isAdminArea()" class="footer  ">
    
    <h2 style="">
       Rejoignez Notre newsletter
    </h2>
    <p>
      Recevez les dernières nouvelles et mises à jour de Medico.pt par e-mail.
    </p>

    <div style="margin-left: auto;
margin-right: auto;
width: 80%;
height: 45px;" class="input-group mb-3">
      <input type="text" class="form-control" placeholder="Votre adresse e-mail" aria-label="Votre adresse e-mail" aria-describedby="button-addon2">
      <button style="color: white;" class="btn btn-outline-secondary" type="button" id="button-addon2">S'abonner</button>
    </div>

  <div  class="container ">
    <div class="row ">
      <div class="col-md-4 mt-4" >
        <h5>À propos</h5>
        <ul class="list-unstyled">
          <li><a style="color: white;" href="#">Qui sommes-nous ?</a></li>
          <li><a style="color: white;" href="#">Nous rejoindre</a></li>
          <li><a style="color: white;" href="#">Partenaires</a></li>
        </ul>
      </div>
      <div class="col-md-4 mt-4">
        <h5>Aide</h5>
        <ul class="list-unstyled">
          <li><a style="color: white;" href="#">FAQ</a></li>
          <li><a style="color: white;" href="#">Contact</a></li>
        </ul>
      </div>
      <div class="col-md-4 mt-4">
        <h5>Termes & Confidentialité</h5>
        <ul class="list-unstyled">
          <li><a style="color: white;" href="#">Conditions d'utilisation</a></li>
          <li><a style="color: white;" href="#">Politique de confidentialité</a></li>
        </ul>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12 text-center">
        <p class="text-muted">© 2024 Medico.pt. Tous droits réservés.</p>
      </div>
    </div>
  </div>
</footer>



  <!-- footer admin -->

  <footer style="display: none;" v-else class="footer bg-light ">
  <div class="container ">
    <div class="row ">
      <div class="col-md-4 mt-4" >
        <h5>À propos</h5>
        <ul class="list-unstyled">
          <li><a href="#">Qui sommes-nous ?</a></li>
          <li><a href="#">Nous rejoindre</a></li>
          <li><a href="#">Partenaires</a></li>
        </ul>
      </div>
      <div class="col-md-4 mt-4">
        <h5>Aide</h5>
        <ul class="list-unstyled">
          <li><a href="#">FAQ</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </div>
      <div class="col-md-4 mt-4">
        <h5>Termes & Confidentialité</h5>
        <ul class="list-unstyled">
          <li><a href="#">Conditions d'utilisation</a></li>
          <li><a href="#">Politique de confidentialité</a></li>
        </ul>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12 text-center">
        <p class="text-muted">© 2024 Medico.pt. Tous droits réservés.</p>
      </div>
    </div>
  </div>
</footer>



</template>

<script>

export default {
  data() {
    return {
      nav: window.location.pathname.startsWith('/admin') ? '/admin' : '/',
      nom: '',
      prenom: '',
      userType: '',
      isNavbarOpen: false

    };

  },
  methods: {
    toggleNavbar() {
            this.isNavbarOpen = !this.isNavbarOpen;
        },
    isPatientLoggedIn() {
      const token = localStorage.getItem('token');
      const userType = localStorage.getItem('userType');
     this.nom = localStorage.getItem('nom');
     this.prenom = localStorage.getItem('prenom');
     this.userType = localStorage.getItem('userType');
     console.log(this.nom)
    console.log(this.prenom)
    console.log(this.userType)

      return token && userType === 'patient';
      
    },
   logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userType');
      localStorage.removeItem('nom');
      localStorage.removeItem('prenom');
      this.$router.push({ path: '/' });
    },

    isAdminArea() {
    return this.$route.path.startsWith('/admin');
  }

  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar {
  padding: 15px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease, box-shadow 0.5s ease;
}

.navbar.navbar-light.bg-light {
  background-color: #fff;
}

.navbar-brand {
  font-weight: 700;
  font-size: 1.25rem;
}

.nav-link {
  color: rgb(4, 56, 115);
  font-weight: 500;
}

.nav-link:hover {
  color: #002244;
}

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
}

.collapse.navbar-collapse {
  transition: height 0.5s ease;
}

.collapse.navbar-collapse.show {
  height: auto;
}

.dropdown-menu {
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.btn-primary {
  background-color: rgb(4, 56, 115);
  border-color: rgb(4, 56, 115);
}

.btn-primary:hover {
  background-color: #022e5c;
  border-color: #022e5c;
}

.btn-outline-primary {
  color: rgb(4, 56, 115);
  border-color: rgb(4, 56, 115);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgb(4, 56, 115);
  border-color: rgb(4, 56, 115);
}

.button_pratcitien{
  border: 1px solid #FFE492; border-radius: 8px; color: #4F9CF9;
  padding: 10px 20px;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 13px;
}

.button_pratcitien:hover{
  background-color: #FFE492;
  color: white;
  font-weight: 600;
}



.button_se_connecter{
  background-color: #4F9CF9; border: none;
  padding: 10px 20px;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 13px;
  color: #fff;
}

.button_se_connecter:hover{
  background-color: #fff;
  font-weight: 600;
  color: #4F9CF9;
  border: 1px solid #4F9CF9;
}







</style>
