<template>
    <div class="header">
        <img src="https://media.istockphoto.com/id/1308682666/vector/blue-gradient-soft-background.jpg?s=612x612&w=0&k=20&c=CBSD2BDe2uMi-Zm65ny6KoPKXsTPdk5K8wt_vMIb3Hc=" alt="logo" class="header-image">
    </div>
    <div class="d-flex justify-content-between align-items-center my-3">
        <div>
            <img class="profile-image"
                src="https://hips.hearstapps.com/hmg-prod/images/portrait-of-a-happy-young-doctor-in-his-clinic-royalty-free-image-1661432441.jpg?crop=0.66698xw:1xh;center,top&resize=1200:*"
                alt="profile image">
        </div>
        <div v-if="toggleEdit" >
            <button @click="toggleEdit = false" class="button_setting">
                Cancel
            </button>
            <button @click="updateDoctorinfo" class="button_setting">
                Save
            </button>
        </div>
        <div v-else>
            <button class="button_setting" @click="toggleEdit = true">
                Edit
            </button>
        </div>
    </div>

    <!-- Formulaire Parametre -->
    <div class="card_settings">
        <div class="d-flex flex-wrap">
            <div class="form-group m-2">
                <label for="nom">Nom</label>
                <input v-model="Nom" type="text" class="form-control form_login" id="nom">
            </div>
            <div class="form-group m-2">
                <label for="prenom">Prénom</label>
                <input v-model="Prenom" type="text" class="form-control form_login" id="prenom">
            </div>
            <div class="form-group m-2">
                <label for="email">Email</label>
                <input v-model="Email" type="text" class="form-control form_login" id="email">
            </div>
            <div class="form-group m-2">
                <label for="telephone">Téléphone</label>
                <input v-model="tel" type="text" class="form-control form_login" id="telephone">
            </div>
            <div class="form-group m-2">
                <label for="adresse">Adresse</label>
                <input v-model="Adresse" type="text" class="form-control form_login" id="adresse" @input="getAddressDetails">
                <ul v-if="addressSuggestions.length > 0" class="address-suggestions">
                    <li v-for="(suggestion, index) in addressSuggestions" :key="index" @click="selectAddressSuggestion(suggestion)">
                        {{ suggestion }}
                    </li>
                </ul>
            </div>
            <div class="form-group m-2">
                <label for="ville">Ville</label>
                <input v-model="Ville" type="text" class="form-control form_login" id="ville">
            </div>
            <div class="form-group m-2">
                <label for="code_postal">Code Postal</label>
                <input v-model="code_postal" type="text" class="form-control form_login" id="code_postal">
            </div>
            <div class="form-group m-2">
                <label for="specialite">Spécialité</label>
                <input v-model="specialite" type="text" class="form-control form_login" id="specialite">
            </div>
            <div class="form-group m-2">
                <label for="description">Description</label>
                <textarea v-model="description" class="form-control form_login" id="description"></textarea>
            </div>
        </div>
        <div class="form-group mt-4 mb-5">
            <label>Plage horaire (pour chaque jour de la semaine)</label>
            <div class="mt-4" v-for="(horaire, index) in horraire" :key="index">
                <label>{{ horaire.jour }}</label>
                <div class="d-flex align-items-center">
                    <input type="checkbox" v-model="horaire.ferme" @change="toggleFerme(horaire)">
                    <label class="ml-2">Fermé</label>
                    <div v-if="!horaire.ferme" class="d-flex ml-3">
                        <select v-model="horaire.debut" class="form-control form_login mr-2" style="width: 45%;">
                            <option value="" disabled selected>Choisissez une heure</option>
                            <option v-for="(heure, heureIndex) in genererHeures()" :value="heure" :key="heureIndex">{{ heure }}</option>
                        </select>
                        <span>-</span>
                        <select v-model="horaire.fin" class="form-control form_login ml-2" style="width: 45%;">
                            <option value="" disabled selected>Choisissez une heure</option>
                            <option v-for="(heure, heureIndex) in genererHeures()" :value="heure" :key="heureIndex">{{ heure }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'AdminSettings',
    data() {
        return {
            Nom: '',
            Prenom: '',
            Email: '',
            Taille: '',
            DateNaissance: '',
            specialite: '',
            code_postal: '',
            description:'',
            tel: '',
            Sexe: '',
            Adresse: '',
            Ville: '',
            horraire: [],
            addressSuggestions: [],
            street:'',
            longitude:'',
            latitude:'',
          
            toggleEdit: false,
            selectedHours: {
      Monday: { startHour: '', endHour: '' },
      Tuesday: { startHour: '', endHour: '' },
      Wednesday: { startHour: '', endHour: '' },
      Thursday: { startHour: '', endHour: '' },
      Friday: { startHour: '', endHour: '' },
      Saturday: { startHour: '', endHour: '' },
      Sunday: { startHour: '', endHour: '' }
    },
    daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  
    hours: ['01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00' , '21:00', '22:00', '23:00', '00:00']
  

        }
    },
    methods: {
        selectAddressSuggestion(address) {
            // Logique pour sélectionner une suggestion d'adresse
            // Mettez à jour la valeur de l'adresse avec l'adresse sélectionnée
            this.Adresse = address;
            console.log(address)
            
            
            // Effacez les suggestions d'adresse une fois qu'une suggestion est sélectionnée
            this.addressSuggestions = [];
        },

      
        getAddressDetails() {
    const address = this.Adresse;
    const mapboxAccessToken = 'pk.eyJ1IjoiZW56b3YiLCJhIjoiY2x3dDhuZGtxMDE3dDJqc2F0dHRnMGhocyJ9.w4ghuWWoBjSzHyHKHx8R9A'; // Remplacez VOTRE_TOKEN_MAPBOX par votre token Mapbox

    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${mapboxAccessToken}`)
    .then(response => {
        const features = response.data.features;
        if (features.length > 0) {
            this.addressSuggestions = features.map(feature => feature.place_name);
            // Récupérer les détails de localisation de la première caractéristique trouvée
            const firstFeature = features[0];
            const city = firstFeature.context.find(context => context.id.startsWith('place')).text;
            const postalCode = firstFeature.context.find(context => context.id.startsWith('postcode')).text;
            const longitude = firstFeature.center[0];
            const latitude = firstFeature.center[1];
            
            this.Ville = city;
            this.code_postal = postalCode
            this.longitude =longitude
            this.latitude= latitude
            // Afficher les détails dans la console ou les utiliser comme vous le souhaitez
          
        } else {
            // Aucune caractéristique trouvée
            console.warn('Aucune caractéristique de localisation trouvée pour l\'adresse fournie.');
        }
    })
    .catch(error => {
        console.error('Erreur lors de la récupération des détails de localisation depuis Mapbox :', error);
    });
},
        
        fetchDoctorinfo(){
        // fetch doctor info
        const token = localStorage.getItem('token')

        axios.get('http://13.49.196.22/profile/doctor', {
            headers: {
                Authorization: `${token}`
            }
        })
        .then((response) => {
            console.log(response.data)
            this.Nom = response.data.nom
            this.Prenom = response.data.prenom
            this.Email = response.data.email
            this.tel = response.data.tel
            this.Adresse = response.data.adresse
            this.Ville = response.data.ville
            this.horraire = response.data.horaire
            this.code_postal = response.data.code_postal
            this.specialite = response.data.specialite
            this.description = response.data.description
        })
        .catch((error) => {
            console.log(error)
        })
},
    updateDoctorinfo(){
        axios.put(`http://13.49.196.22/profile/doctor`, {
            email : this.Email,
            nom : this.Nom,
            prenom : this.Prenom,
            adresse : this.Adresse,
            ville : this.Ville,
            tel : this.tel,
            code_postal : this.code_postal,
            description : this.description,
            specialite : this.specialite,
            horaire : this.horraire,
            longitude:this.longitude,
            latitude: this.latitude


    }, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(response => {
        console.log('Event updated successfully', response);
        this.fetchDoctorinfo();
        this.showModal = false;
    })
    .catch(error => {
        console.error('There was an error!', error);
    });
    },
    genererHeures() {
        const heures = [];
        for (let heure = 0; heure < 24; heure++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const heureStr = ('0' + heure).slice(-2);
                const minuteStr = ('0' + minute).slice(-2);
                heures.push(`${heureStr}:${minuteStr}`);
            }
        }
        return heures;
    },
    toggleFerme(horaire) {
        if (horaire.ferme) {
            horaire.debut = null;
            horaire.fin = null;
        }
        console.log(horaire)
    }


    },
    mounted(){
        this.fetchDoctorinfo()
    }   
}


</script>



<style>
.header {
    width: 100%;
    height: 150px;
    border-radius: 0px 0px 0px 20px;
    overflow: hidden;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 6px solid white;
    margin-top: -75px;
    margin-left: 50px;
}

.card_settings {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
}

.form_login {
    width: 100%;
    padding: 10px;
    border: 1px solid #adadad;
    border-radius: 5px;
}

.button_setting {
    background-color: white;
    color: #4E6DCE;
    border: 1px solid #4E6DCE;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 10px;
    width: 70px;
}

.button_setting:hover {
    color: white;
    background-color: #4E6DCE;
    font-weight: 500;
}

.address-suggestions {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    border-top: none;
    position: absolute;
    background: #fff;
    z-index: 1000;
    width: 100%;
}

.address-suggestions li {
    padding: 10px;
    cursor: pointer;
}

.address-suggestions li:hover {
    background-color: #ddd;
}
</style>