<template>
     <!-- Page Vous Etes Practicien rejoigner nous -->




     <div class="">
          <Div class="" style="background-color:  #043873 ;  padding: 40px 20px; color: white;">



               <div class="container">
                    <div class="row">
                         <div class="col-md-6 d-flex align-items-center">
                              <div style="max-width: 700px; text-align: left;">
                                   <h3 class="mobile_h3" style="font-family: Arial Black; font-size: 40px; margin-bottom: 25px;">
                                   Adoptez L'Efficacité Avec MEDICO.PT
                                   </h3>
                                   <p style="font-size: 15px;">
                                        Gérez vos rendez-vous, améliorez la communication avec vos patients et optimisez votre temps.
                                   </p>
                                   <button
                                        style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                        Essayer Gratuitement
                                   </button>
                              </div>
                         </div>
                         <div class="col-md-6">
                              <img class="no-display" style="height: 500px; width: 100%; border-radius: 5px;"
                                   src="https://img.freepik.com/photos-gratuite/grave-docteur-lisant-dossiers_23-2147896176.jpg?t=st=1718962882~exp=1718966482~hmac=a9fc3e24c3d87aa6bb78c1141e76391f4d9a06d7349ab2d4e9951281d39dbeae&w=2000">

                         </div>
                    </div>

               </div>

          </Div>

          <div class="container mt-5 mb-3">
               <div class="row">
                    <div class="col-md-6 d-flex align-items-center">
                         <div style="max-width: 500px; text-align: left;">
                              <h3 style="font-family: Arial Black; font-size: 40px; margin-bottom: 25px;">
                                   Gagner en <span class="highlight">Visibilité</span>
                              </h3>
                              <p style="font-size: 15px;">
                                   Gagnez en visibilité et attirez plus de patients avec Medico.Pt, Chaque jours plus de
                                   1000 patients se connecte pour prendre RDV
                              </p>
                              <button
                                   style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                   Essayer Gratuitement
                              </button>
                         </div>
                    </div>
                    <div class="col-md-6">
                         <img class="no-display" style="height: 400px; width: 100%; border-radius: 5px;"
                              src="https://img.freepik.com/free-vector/man-looking-building-through-magnifier_778687-1170.jpg?t=st=1718965911~exp=1718969511~hmac=634ccf7967b694e25d7c771fd6167080280d9a2428ab0e9f47e9c6f66e15058b&w=1800">

                    </div>
               </div>

          </div>

          <div class="container mt-5 mb-3">
               <div class="row">
                    <div class="col-md-6">
                         <img class="mobile_picture" style="height: 400px; width: 100%; border-radius: 5px;"
                              src="https://img.freepik.com/free-vector/appointment-booking-with-smartphone-woman_23-2148555981.jpg?t=st=1718964983~exp=1718968583~hmac=3f1f0fa1303c97e934e74096ca272e97d5d9b9eaf0635f185aac57a8d641b823&w=1380">

                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                         <div style="max-width: 500px; text-align: left; margin-left: 60px;">
                              <h3 style="font-family: Arial Black; font-size: 40px; margin-bottom: 25px;">
                                   Gestion Simplifiée des <span class="highlight">Rendez-vous</span>
                              </h3>
                              <p style="font-size: 15px;">
                                   Un agenda facile à utiliser, accessible 24/7, permettant aux patients de prendre des
                                   rendez-vous en ligne à tout moment. <br>
                                   Rappels automatiques envoyés aux patients par SMS ou email, réduisant le nombre de
                                   rendez-vous manqués.
                              </p>
                              <button
                                   style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                   Essayer Gratuitement
                              </button>
                         </div>
                    </div>

               </div>

          </div>

          <div class="container mt-5 mb-3">
               <div class="row">
                    <div class="col-md-6 d-flex align-items-center">
                         <div style="max-width: 500px; text-align: left;">
                              <h3 style="font-family: Arial Black; font-size: 40px; margin-bottom: 25px;">
                                   Efficacité et <span class="highlight">Gain de Temps</span>
                              </h3>
                              <p style="font-size: 15px;">
                                   Moins d'appels de prise de rendez-vous, permettant au personnel de se concentrer sur
                                   d'autres tâches importantes.<br>
                                   Gestion automatique des annulations et des reprogrammations, libérant du temps pour
                                   le personnel médical.
                              </p>
                              <button
                                   style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                   Essayer Gratuitement
                              </button>
                         </div>
                    </div>
                    <div class="col-md-6">
                         <img class="no-display" style="height: 400px; width: 100%; border-radius: 5px;"
                              src="https://img.freepik.com/free-photo/busy-businesswoman-working-home-office_329181-9416.jpg?t=st=1718964199~exp=1718967799~hmac=91c826337c5fda14923ddeab07201325f8d7a512ead4dda3cdbf2091120d612d&w=2000">

                    </div>
               </div>

          </div>

          <div class="container mt-5 mb-3">
               <div class="row">
                    <div class="col-md-6">
                         <img class="mobile_picture" style="height: 400px; width: 100%; border-radius: 5px;"
                              src="https://img.freepik.com/free-photo/side-view-smiley-doctor-talking-patient_23-2149856238.jpg?t=st=1718965815~exp=1718969415~hmac=dedcbc841d6678a1a3447c0d658c29d861d403fa72bba440c50fd4f60e84b338&w=2000">

                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                         <div style="max-width: 500px; text-align: left; margin-left: 60px;">
                              <h3 style="font-family: Arial Black; font-size: 40px; margin-bottom: 25px;">
                                   Amélioration de la <span class="highlight">Relation Patient</span>
                              </h3>
                              <p style="font-size: 15px;">
                                   Accès facile aux dossiers médicaux des patients, améliorant la qualité des soins.
                              </p>
                              <button
                                   style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                   Essayer Gratuitement
                              </button>
                         </div>
                    </div>

               </div>

          </div>

          <Div  style="background-color:  #043873 ;  padding: 20px; color: white; margin-top: 140px;">



               <div class="container">
                    <div class="row" style="padding: 50px 20px;">
                         <div class="col-md-6 d-flex align-items-center">
                              <div style="max-width: 500px; text-align: left;">
                                   <h3 style="font-family: Arial Black; font-size: 40px; margin-bottom: 25px;">
                                        Support et Formation
                                   </h3>
                                   <p style="font-size: 15px;">
                                        Équipe de support dédiée pour aider les médecins à utiliser la plateforme
                                        efficacement.<br>
                                        Sessions de formation pour maximiser l'utilisation des fonctionnalités de la
                                        plateforme.
                                   </p>
                                   <button
                                        style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                        Essayer Gratuitement
                                   </button>
                              </div>
                         </div>
                         <div class="col-md-6">
                              <img class="no-display" style="height: 400px; width: 100%; "
                                   src="https://img.freepik.com/photos-gratuite/grave-docteur-lisant-dossiers_23-2147896176.jpg?t=st=1718962882~exp=1718966482~hmac=a9fc3e24c3d87aa6bb78c1141e76391f4d9a06d7349ab2d4e9951281d39dbeae&w=2000">

                         </div>
                    </div>

               </div>

          </Div>


          <div class="container mt-5">
    <h2 style="font-size: 40px; text-align: center;">
        Choisissez <span class="highlight">Votre Offre</span>
    </h2>

    <div class="d-flex justify-content-center  ">
     <div class="row">
          <div class="col-md-4">
               <div class="card" style="border: 1px solid #FFE492; border-radius: 8px; max-width: 350px;">
            <div class="card-body text-center">
                <p>Plan basique</p>
                <span style="font-size: 27px;">19$</span>
                <ul class="list-unstyled mt-3">
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Création de profil professionnel</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Gestion des rendez-vous en ligne (jusqu'à 100/mois)</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Rappels de rendez-vous par email</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Accès à l'agenda en ligne</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Support par email</li>
                </ul>
                <button class="button_se_connecter">Souscrire</button>
            </div>
        </div>
          </div>
          <div class="col-md-4">
               <div class="card text-center" style="background-color: #043873; border-radius: 8px; max-width: 350px; ">
            <div class="card-body text-white">
                <p>Plan avancé</p>
                <span style="font-size: 27px; color: #FFE492;">29$</span>
                <ul class="list-unstyled mt-3">
                    <li style="color: #FFFF;"><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Création de profil professionnel</li>
                    <li style="color: #FFFF;"><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Gestion des rendez-vous en ligne (illimité)</li>
                    <li style="color: #FFFF;"><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Rappels de rendez-vous par SMS</li>
                    <li style="color: #FFFF;"><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Accès prioritaire à l'agenda en ligne</li>
                    <li style="color: #FFFF;"><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Support 24/7 par email et téléphone</li>
                </ul>
                <button class="button_pratcitien">Essayer Gratuitement</button>
            </div>
        </div>
          </div>
          <div class="col-md-4">
               <div class="card" style="border: 1px solid #FFE492; border-radius: 8px; max-width: 350px;">
            <div class="card-body text-center">
                <p>Plan premium</p>
                <span style="font-size: 27px;">39$</span>
                <ul class="list-unstyled mt-3">
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Création de profil professionnel</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Gestion des rendez-vous en ligne (illimité)</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Rappels de rendez-vous par SMS</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Accès prioritaire à l'agenda en ligne</li>
                    <li><svg style="height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg> Support 24/7 par email et téléphone</li>
                </ul>
                <button class="button_se_connecter">Souscrire</button>
            </div>
        </div>
          </div>
     </div>
       

      

       
    </div>
</div>


          <Div  style="background-color:  #043873 ;  padding: 20px; color: white; margin-top: 140px;">



               <div class="container">
                    <div class="mobile_join_us" style="padding: 90px 120px; ">
                         <h3 style="font-family: Arial Black; font-size: 40px; margin-bottom: 25px;">
                    Rejoignez Notre Plateforme de Santé!
                 </h3>
                 <p style="font-size: 15px;">
                    Optimisez votre pratique et simplifiez la gestion de vos rendez-vous en rejoignant Medico.PT. Découvrez une solution innovante et intuitive pour une expérience médicale plus fluide et connectée. Facilitez vos interactions avec vos patients tout en gagnant du temps grâce à notre interface conviviale et nos fonctionnalités avancées. Rejoignez dès aujourd'hui la communauté des professionnels de santé qui ont choisi Medico.PT pour transformer leur pratique!
                 </p>
                 <button class="mt-3"
                                        style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                        Essayer Gratuitement
                                   </button>
                    </div>
                    
                    
               </div>

          </Div>

          <div style="margin-top: 140px;" class="container ">
               <h2  style="    font-size: 40px;">
                    Ce que pensent <span class="highlight">Nos Docteurs</span>
               </h2>
               <div style="place-content: center;" class="d-flex mt-5">
                    <div class="row">
                         <div style="margin-top: 5px;" class="col-md-4">
                              <div
                                   style="background-color: #FFFF; border-radius: 5px; padding: 40px 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                                   <svg class="mb-2 d-flex align-item-start" style="height: 50px; text-align: left;"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="#043873"
                                             d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                                   </svg>
                                   <p style="text-align: left;">
                                        Whitepate is designed as a collaboration tool for businesses that is a full
                                        project management solution.
                                   </p>
                                   <hr>
                                   <div class="d-flex">
                                        <img style="border-radius: 50%; height: 55px; width: 55px;"
                                             src="https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360">
                                        <h4 style="margin-left: auto ; margin-right: auto;">
                                             Enzo Viana <br>
                                             <span style="font-size: 14px;">
                                                  Medecin Generaliste
                                             </span>
                                        </h4>
                                   </div>
                              </div>
                         </div>
                         <div  style="margin-top: 5px;" class="col-md-4">
                              <div
                                   style="background-color: #4F9CF9; border-radius: 5px; padding: 40px 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                                   <svg class="mb-2 d-flex align-item-start" style="height: 50px; text-align: left;"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="#FFFF"
                                             d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                                   </svg>
                                   <p style="color: white; text-align: left; border-bottom: 1px;">
                                        Whitepate is designed as a collaboration tool for businesses that is a full
                                        project management solution.
                                   </p>
                                   <hr style="color: white; ">
                                   <div class="d-flex">
                                        <img style="border-radius: 50%; height: 55px; width: 55px;"
                                             src="https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360">
                                        <h4 style="margin-left: auto ; margin-right: auto;">
                                             Enzo Viana <br>
                                             <span style="font-size: 14px;">
                                                  Medecin Generaliste
                                             </span>
                                        </h4>
                                   </div>
                              </div>
                         </div>
                         <div  style="margin-top: 5px;" class="col-md-4">
                              <div
                                   style="background-color: #4F9CF9; border-radius: 5px; padding: 40px 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                                   <svg class="mb-2 d-flex align-item-start" style="height: 50px; text-align: left;"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="#FFFF"
                                             d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                                   </svg>
                                   <p style="color: white; text-align: left;">
                                        Whitepate is designed as a collaboration tool for businesses that is a full
                                        project management solution.
                                   </p>
                                   <hr style="color: white;">
                                   <div class="d-flex">
                                        <img  style="border-radius: 50%; height: 55px; width: 55px;"
                                             src="https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360">
                                        <h4 style="margin-left: auto ; margin-right: auto;">
                                             Enzo Viana <br>
                                             <span style="font-size: 14px;">
                                                  Medecin Generaliste
                                             </span>
                                        </h4>
                                   </div>
                              </div>
                         </div>
                    </div>

               </div>

          </div>











     </div>




</template>

<script>
import axios from 'axios'

export default {
     name: 'PracticienView',
     data() {
          return {
               Nom: '',
               Prenom: '',
               Email: '',
               Password: '',
               Specialite: '',
               Register: true,
               words: [
                    'Médico de Família',
                    'Cirurgião',
                    'Dentista',
                    'Enfermeiro(a)',
                    'Farmacêutico(a)',
                    'Fonoaudiólogo(a)',
                    'Psicólogo(a)',
                    'Fisioterapeuta',
                    'Obstetriz',
                    'Oftalmologista',
                    'Pediatra',
                    'Ginecologista e Obstetra',
                    'Radiologista',
                    'Anestesista',
                    'Cardiologista',
                    'Dermatologista',
                    'Gastroenterologista',
                    'Neurologista',
                    'Oncologista',
                    'Pneumologista',
                    'Reumatologista',
                    'Urologista',
                    'Quiropraxista',
                    'Osteopata',
                    'Podólogo'
               ],
               resettingPassword: false, // Ajout de la variable resettingPassword
               resetEmail: '', // Variab
          }
     },
     methods: {
          async resetPassword() {
               try {
                    const response = await axios.post('https://13.49.196.22/reset-password', {
                         email: this.resetEmail
                    });
                    if (response.status === 200) {
                         this.requestSuccess = true;
                         this.resetEmail = '',
                              this.requestSuccessMessage = response.data.message;
                    } else {
                         this.requestError = true;
                         this.requestErrorMessage = response.data.message;
                    }
               } catch (error) {
                    console.error('Erreur lors de la demande de réinitialisation du mot de passe :', error);
                    this.requestError = true;
                    this.requestErrorMessage = 'Erreur lors de la demande de réinitialisation du mot de passe.';
               }
          },
          register() {
               axios.post('http://13.49.196.22/signup/doctor', {
                    nom: this.Nom,
                    prenom: this.Prenom,
                    email: this.Email,
                    password: this.Password,
                    specialite: this.Specialite,
               })
                    .then((response) => {
                         console.log(response)
                         localStorage.setItem('token', response.data.token)
                         this.$router.push('/')

                    })
                    .catch((error) => {
                         console.log(error)
                    })
          },
          login() {
               console.log(this.Email, this.Password, 'Email', 'Password')
               axios.post('http://13.49.196.22/login/doctor', {
                    email: this.Email,
                    password: this.Password

               })
                    .then((response) => {
                         localStorage.setItem('token', response.data.token)
                         localStorage.setItem('id', response.data.id)
                         localStorage.setItem('userType', response.data.userType)
                         console.log('id', response.data.id)
                         this.$router.push({ name: 'admin' });
                    })
                    .catch((error) => {
                         console.log(error)
                    })
          }
     }
}

</script>


<style>
.card {
     margin-top: 20%;
     margin-bottom: 20%;
     border-radius: 10px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     padding: 20px;
     background-color: white;
}

.svg_join_us {
     background-color: rgb(13, 77, 195);
     padding: 10px 1px;
     height: 60px;
     width: 60px;
     border-radius: 10px;
     margin-bottom: 20px;
}

.pricing-table {
     max-width: 1200px;
     margin: 20px auto;
     padding: 20px;
     background-color: #fff;
     border-radius: 8px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pricing-plan {
     display: flex;
     justify-content: space-around;
     margin-bottom: 20px;
}

.pricing-plan__column {
     flex: 1;
     padding: 20px;
     text-align: center;
}

.pricing-plan__title {
     font-size: 24px;
     margin-bottom: 10px;
}

.pricing-plan__price {
     font-size: 36px;
     font-weight: bold;
     color: #007bff;
     margin-bottom: 10px;
}

.pricing-plan__features {
     list-style: none;
     padding: 0;
     margin: 0;
}

.pricing-plan__feature {
     margin-bottom: 10px;
}

.btn {
     display: inline-block;
     padding: 10px 20px;
     background-color: #007bff;
     color: #fff;
     text-decoration: none;
     border-radius: 5px;
}

.highlight {
     position: relative;
     display: inline-block;
}

.highlight::before {
     content: '';
     position: absolute;
     left: 0;
     bottom: 0;
     width: 100%;
     height: 0.2em;
     background: #FFE492;
     z-index: -1;
     transform: rotate(-2deg);
}

.pricing-table {
     display: flex;
     justify-content: space-around;
     padding: 20px;
}

.pricing-plan {
     border: 1px solid #ccc;
     border-radius: 8px;
     padding: 20px;
     text-align: center;
     max-width: 300px;
}

.pricing-plan h3 {
     font-size: 24px;
     margin-bottom: 10px;
}

.pricing-plan .price {
     font-size: 20px;
     font-weight: bold;
     margin-bottom: 20px;
}

.pricing-plan ul {
     list-style: none;
     padding: 0;
     margin-bottom: 20px;
}

.pricing-plan ul li {
     margin-bottom: 10px;
}

.pricing-plan button {
     border: none;
     border-radius: 8px;
     background-color: #4F9CF9;
     color: #fff;
     padding: 10px 20px;
     font-size: 16px;
     cursor: pointer;
}

.pricing-plan button:hover {
     background-color: #357ABD;
}

@media only screen and (max-width: 768px) {
     .mobile_join_us{
          padding: 10px !important ;
}
     .mobile_picture{
          height: 200px !important;
          width: auto !important;
     }
     .mobile_h3{
          font-size: 30px !important;
     }
}

</style>