<template  >

  <!-- Modal -->
  <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showModal }">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">Ajouter un Evenements</h5>
          <button type="button" class="close" @click="showModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
    <form @submit.prevent="addEvent"> 
                <div class="form-group">
                <label for="eventTitle">Titre</label>
                <input type="text" class="form-control" id="eventTitle" v-model="eventTitle" required>
                </div>
                <div class="form-group mt-3"> 
                <label for="eventDescription">Description</label>
                <textarea class="form-control" id="eventDescription" v-model="eventDescription" required></textarea>

                </div>
                

                <div class="form-group mt-3">
                <label for="eventStartDate">Date</label> 
                <input type="date" class="form-control" id="eventStartDate" v-model="eventStartDate" required>
                </div>

                <div class="form-group mt-3">
                <label for="eventStartTime">Heure de début</label> 
                <input type="time" class="form-control" id="eventStartTime" v-model="eventStartTime" required> 
                </div>

                <!-- Date de fin -->

                <div class="form-group mt-3">
                <label for="eventEndDate">Date de fin</label>
                <input type="date" class="form-control" id="eventEndDate" v-model="eventEndDate" required>
                </div>


                <div class="form-group mt-3">
                <label for="eventEndTime">Heure de fin</label>
                <input type="time" class="form-control" id="eventEndTime" v-model="eventEndTime" required>
                </div>

                <!-- 4 couleur au choix predefenis  -->

                <div class="form-group mt-3">
                <label for="eventColor">Couleur</label>
                <select class="form-control" id="eventColor" v-model="eventColor" required>
                    <option value="#fd8686">Rouge</option>
                    <option value="#04d2d4">Bleu</option>
                    <option value="#a0e169">Vert</option>
                    <option value="#f9d62e">Jaune</option>
                    <option value="#ffa77c">Orange</option>
                </select>
                
                </div>

                <!-- Ajout D'un Patient avec possibilite de Choisir un Patient -->

                <div class="form-group mt-3">
                <label for="eventPatient">Patient</label>
               
                <input v-if="newPatient" type="text" class="form-control" id="eventPatient" v-model="eventPatient" >
                
                <select v-else class="form-control" id="eventPatient" v-model="eventPatient" >
                    <option value="">Choisir un patient</option>
                    <option v-for="patient in patients" :key="patient.id" :value="patient.id">{{ patient.name }}</option>
                </select>
                <a style="font-size: 13px; color: rgb(4, 56, 115); cursor: pointer;" @click="newPatient = false"> Choisir un Patient Existant </a>
                
                </div>

                <button style="background-color: rgb(4, 56, 115); color: white;" type="submit" class="btn btn-primary mt-3">Ajouter l'événement</button>

                



    </form>

        </div>
      </div>
    </div>
  </div>

  <!-- Modal Settings Caed_calendar -->

  <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showSettingsModal }">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-between">
                <h5 class="modal-title">Paramètres</h5>
                <button type="button" class="close" @click="showSettingsModal = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <!-- Heures d'ouverture et de fermeture -->

                <div class="form-group">
                    <label for="startHour">Heure de début</label>
                    <input type="time" class="form-control" id="startHour" v-model="startHour" required>
                </div>
                <div class="form-group mt-3">
                    <label for="endHour">Heure de fin</label>
                    <input type="time" class="form-control" id="endHour" v-model="endHour" required>
                </div>

                

                <!-- Vacances -->

                <div class="form-group mt-3">
                    <label for="holidays">Vacances</label>
                    <input type="text" class="form-control" id="holidays" v-model="holidays">
                    <small id="holidaysHelp" class="form-text text-muted">Entrez les dates de vacances, séparées par des virgules (par exemple : 01/07/2024, 15/08/2024).</small>
                </div>

            </div>
        </div>
    </div>
</div>


    <!-- Modal Modifier evenement -->

    <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showEditModal }">
       
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">Modifier Votre Evenements</h5>
          <button type="button" class="close" @click="showEditModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
    <form> 
                <div class="form-group">
                <label for="eventTitle">Titre</label>
                <input type="text" class="form-control" id="eventTitle" v-model="eventTitle" required>
                </div>
                <div class="form-group mt-3"> 
                <label for="eventDescription">Description</label>
                <textarea class="form-control" id="eventDescription" v-model="eventDescription" required></textarea>

                </div>
                

                <div class="form-group mt-3">
                <label for="eventStartDate">Date</label> 
                <input type="date" class="form-control" id="eventStartDate" v-model="eventStartDate" required>
                </div>

                <div class="form-group mt-3">
                <label for="eventStartTime">Heure de début</label> 
                <input type="time" class="form-control" id="eventStartTime" v-model="eventStartTime" required> 
                </div>

                <!-- Date de fin -->

                <div class="form-group mt-3">
                <label for="eventEndDate">Date de fin</label>
                <input type="date" class="form-control" id="eventEndDate" v-model="eventEndDate" required>
                </div>


                <div class="form-group mt-3">
                <label for="eventEndTime">Heure de fin</label>
                <input type="time" class="form-control" id="eventEndTime" v-model="eventEndTime" required>
                </div>

                <!-- 4 couleur au choix predefenis  -->

                <div class="form-group mt-3">
                <label for="eventColor">Couleur</label>
                <select class="form-control" id="eventColor" v-model="eventColor" required>
                    <option value="#fd8686">Rouge</option>
                    <option value="#04d2d4">Bleu</option>
                    <option value="#a0e169">Vert</option>
                    <option value="#f9d62e">Jaune</option>
                    <option value="#ffa77c">Orange</option>
                </select>
                
                </div>

                <!-- Ajout D'un Patient avec possibilite de Choisir un Patient -->

                <div class="form-group mt-3">
                <label for="eventPatient">Patient</label>
               
                <input v-if="newPatient" type="text" class="form-control" id="eventPatient" v-model="eventPatient" >
                
                <select v-else class="form-control" id="eventPatient" v-model="eventPatient" >
                    <option value="">Choisir un patient</option>
                    <option v-for="patient in patients" :key="patient.id" :value="patient.id">{{ patient.name }}</option>
                </select>
                <a style="font-size: 13px; color: rgb(4, 56, 115); cursor: pointer;" @click="newPatient = false"> Choisir un Patient Existant </a>
                
                </div>

                <button style="background-color: rgb(4, 56, 115); color: white;" @click="updateEvent" class="btn btn-primary mt-3">Modifier l'événement</button>
                <button style="background-color: #fd8686; color: white; border: #fd8686; margin-left: 5px;" @click="deleteEvent()" type="button" class="btn btn-primary mt-3">Supprimer l'événement</button>

                



    </form>

        </div>
      </div>
    </div>
  </div>

   

        
    <!-- Affiche moi les jours de la semaine -->

   <div :class="{ 'blurred': showModal || showEditModal }" >
    <div v-if="selectedOption === 'Jours'" class="card_calendar">
        <div class="mb-5 d-flex  justify-content-between">
                    <span class="span_current_week d-flex align-items-center">
                        <button @click="previousDay" style=" margin-right: 7px; background-color: white;
border: none;
cursor: pointer;">
                            <svg style="height: 20px; width: auto ;" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512">
                                <path
                                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                            </svg> </button>
                        <button @click="nextDay" style="margin-left: 0px ;  background-color: white;
border: none;
cursor: pointer;">
                            <svg style="height: 20px; width: auto ;" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512">
                                <path
                                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                            </svg>
                        </button>
                    </span>
                    <div class="d-flex align-items-center">
                        <svg style="height: 20px; width: auto ; margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path fill="#919191"
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                        </svg>
                        <svg style="height: 20px; width: auto ; margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="#919191"
                                d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                        </svg>
                        <div class="calendar-selector">
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Jours' }"
                                @click="selectOption('Jours')">Jours</div>
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Semaine' }"
                                @click="selectOption('Semaine')">Semaine</div>
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Mois' }"
                                @click="selectOption('Mois')">Mois</div>
                        </div>
                    </div>

                </div>

                 <div class="card-calendar-container">
                    
                 </div>
                 <div class="header">
        <div class="hour-column"></div>
        <div v-for="(day, index) in days" :key="day" :class="{ 'day-column': true, 'current-day': index === currentDay }">
            {{ day }}
        </div>
    </div>
    <div  style="    max-height: 66vh; overflow-y: auto;">
        <div class="body">
            <div v-for="hour in filteredHours" :key="hour" class="hour-row">
                <div class="hour">{{ hour }}h00 </div>
                <div v-for="day in filteredDays" :key="day" class="event-cell">
                    <!-- Afficher les événements pour ce jour et cette heure -->
                    <div v-for="event in eventsForHour(day, hour)" :key="event.id">
                        <div style="position: relative; margin-left: auto; margin-right: auto;" class="event" :style="{ backgroundColor: event.color, top: calculateEventTop(event), height: calculateEventHeight(event) + 'px' }" @click="openModal(event)">
                            <strong>{{ event.title }}</strong>
                            <p> {{ event.startHour }} - {{ event.endHour }} </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="current-time-bar" :style="{ top: currentTimePosition + 'px' }" v-if="currentTimePosition >= 0 && currentTimePosition <= (filteredHours.length * 60)"></div>
        </div>
    </div>

    </div>

    <div v-if="selectedOption === 'Mois'" class="card_calendar">
        <div class="mb-5 d-flex  justify-content-between">
                    <span class="span_current_week d-flex align-items-center">
                        Semaine du {{ startDate }} au {{ endDate }}
           {{ year }}
                        <button @click="previousWeek" style="margin-left: 10px ; margin-right: 7px; background-color: white;
border: none;
cursor: pointer;">
                            <svg style="height: 20px; width: auto ;" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512">
                                <path
                                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                            </svg> </button>
                        <button @click="nextWeek" style="margin-left: 0px ;  background-color: white;
border: none;
cursor: pointer;">
                            <svg style="height: 20px; width: auto ;" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512">
                                <path
                                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                            </svg>
                        </button>
                    </span>
                    <div class="d-flex align-items-center">
                        <svg style="height: 20px; width: auto ; margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path fill="#919191"
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                        </svg>
                        <svg style="height: 20px; width: auto ; margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="#919191"
                                d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                        </svg>
                        <div class="calendar-selector">
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Jours' }"
                                @click="selectOption('Jours')">Jours</div>
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Semaine' }"
                                @click="selectOption('Semaine')">Semaine</div>
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Mois' }"
                                @click="selectOption('Mois')">Mois</div>
                        </div>
                    </div>

                </div>

                 <div class="card-calendar-container">
                    
                 </div>
                 <table>
        <thead>
            <tr>
                <th v-for="day in days" :key="day">{{ day }}</th>
            </tr>
        </thead>
        <tbody>
            <tr class="td_cell" v-for="week in weeks" :key="week" style="height: 60px;">
                <td class="td_cell" v-for="day in week" :key="day" style="position: relative; text-align: left; padding-left: 10px; vertical-align: top;">
                    {{ day }}
                   
                   
                    <div  v-if="countEventsByDay(day) > 0" class="CountEvent " >
                        <span style="
    font-weight: 200;">{{ countEventsByDay(day) }} Evenements </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    </div>
   
    <div v-if="selectedOption === 'Semaine'" class="card_calendar">
        <div class="mb-5 d-flex  justify-content-between">
                    <span class="span_current_week d-flex align-items-center">
                        Semaine du {{ startDate }} au {{ endDate }}
           {{ year }}
                        <button @click="previousWeek" style="margin-left: 10px ; margin-right: 7px; background-color: white;
border: none;
cursor: pointer;">
                            <svg style="height: 20px; width: auto ;" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512">
                                <path
                                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                            </svg> </button>
                        <button @click="nextWeek" style="margin-left: 0px ;  background-color: white;
border: none;
cursor: pointer;">
                            <svg style="height: 20px; width: auto ;" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512">
                                <path
                                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                            </svg>
                        </button>
                    </span>
                    <div class="d-flex align-items-center">
                        <svg @click="showModal = true" style="height: 20px; width: auto ; margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path fill="#919191"
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                        </svg>
                        <svg @click="showSettingsModal = true" style="height: 20px; width: auto ; margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="#919191"
                                d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                        </svg>
                        <div class="calendar-selector">
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Jours' }"
                                @click="selectOption('Jours')">Jours</div>
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Semaine' }"
                                @click="selectOption('Semaine')">Semaine</div>
                            <div class="selector-option" :class="{ 'selected': selectedOption === 'Mois' }"
                                @click="selectOption('Mois')">Mois</div>
                        </div>
                    </div>

                </div>

                 <div class="card-calendar-container">
                    
                 </div>
                 <div class="header">
    <div class="hour-column"></div>
    <div v-for="(day, index) in days" :key="day" :class="{ 'day-column': true, 'current-day': isCurrentDay(index) }">
      {{ day }}
    </div>
  </div>
  <div  style="    max-height: 66vh; overflow-y: auto;">
    <div class="body">
      <div v-for="hour in filteredHours" :key="hour" class="hour-row">
        <div class="hour">{{ hour }}h00 </div>
        <div v-for="day in days" :key="day" :class="{ 'event-cell': true, 'current_cell': day === days[currentDay] }" @click="createEvent(day, hour)">
          <!-- Afficher les événements pour ce jour et cette heure -->
          <div v-for="event in eventsForHour(day, hour)" :key="event.id">
  <div style="position: relative; margin-left: auto; margin-right: auto;  " class="event" :style="{ color: event.colortext, borderLeft: event.borderLeft,  backgroundColor: event.color, top: calculateEventTop(event), height: calculateEventHeight(event) + 'px' }" @click="openModal(event)">
    <strong>{{ event.title }}</strong>
    <p> {{ event.description }} </p> 
  </div>
</div>
        </div>
      </div>
      <div class="current-time-bar" :style="{ top: currentTimePosition + 'px' }" v-if="currentTimePosition >= 0 && currentTimePosition <= (filteredHours.length * 60)"></div>
    </div>
  </div>

    </div>

</div>

   



</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import moment from 'moment-timezone';

const portugueseDate = moment.utc('2024-05-30').tz('Europe/Lisbon');
console.log(portugueseDate.format()); // Affiche la date dans le fuseau horaire portugais

// Convertir une heure spécifique au fuseau horaire portugais
const portugueseTime = moment.utc('2024-05-30T12:00:00').tz('Europe/Lisbon');
console.log(portugueseTime.format('HH:mm:ss')); // Affiche l'heure dans le fuseau horaire portugais


export default {
    data() {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(startDate.getDate() - today.getDay()); // Début de la semaine
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        return {
            eventTitle: '',
      eventDescription: '',
      eventColor: '#CCCCCC',
      eventStartDate: '',
      eventStartTime: '',
      eventEndTime: '',
    showCard: false,
    startDate: startDate.toLocaleDateString('pt-PT', { month: 'short', day: 'numeric' }),
    endDate: endDate.toLocaleDateString('pt-PT', { month: 'short', day: 'numeric' }),
    month: today.toLocaleDateString('pt-PT', { month: 'long' }),
    year: today.getFullYear(),
    days: [], // Initialiser comme un tableau vide
    selectedOption: 'Semaine',
    hours: Array.from({ length: 24 }, (_, i) => i),
    currentTimePosition: 0,
    isFormVisible: false,
    selectedDay: null,
            startHourEvent: null,
            endHourEvent: null,
    currentWeekStartDate: new Date(),
    currentDay: new Date().getDay(),
    startHour: 8,
    endHour: 20,
    daysMonth: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    currentDate: new Date(),
    events: [],
    isClosureFormVisible: false,
    closureDay: '',
    closureOption: 'start',
    closureDescription: '',
    fetchedEvents: [],
    showModal: false,
    eventID: '',

      eventEndDate: '',
      eventPatient: '',
      patients: [], // Assurez-vous de définir correctement cette liste de patients
      newPatient: true,
      showSettingsModal: false,
      showEditModal: false,

        }
    },

    mounted() {
        this.updateCurrentTime();
        setInterval(this.updateCurrentTime, 60000); // Mise à jour toutes les minutes
        this.getCurrentWeekDates();
        this.addEventTomorrow();
        this.fetchevents();
       
    },
    computed: {
        
        currentMonthName() {
      const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
      return monthNames[this.currentDate.getMonth()];
    },
    
  
        filteredHours() {
            // Filtrer les heures en fonction de l'heure de début et de fin choisie
            return this.hours.slice(this.startHour, this.endHour + 1);
        },
        filteredDays() {
        return this.days.filter(day => day === this.days[this.currentDay]);
    },
    weeks() {
      const firstDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
      const numDaysInMonth = lastDayOfMonth.getDate();

      const firstDayOfWeek = firstDayOfMonth.getDay();
      const weeks = [[]];
      let currentWeek = 0;

      for (let i = 0; i < firstDayOfWeek; i++) {
        weeks[currentWeek].push('');
      }

      for (let day = 1; day <= numDaysInMonth; day++) {
        if (weeks[currentWeek].length === 7) {
          currentWeek++;
          weeks[currentWeek] = [];
        }
        weeks[currentWeek].push(day);
      }

      while (weeks[currentWeek].length < 7) {
        weeks[currentWeek].push('');
      }

      return weeks;
    }
  
        
        
    },
    methods: {

        createEvent(day, hour) {
    // Créez un nouvel événement avec les informations données
    const newEvent = {
      id: this.events.length + 1,
      startDate: new Date(day).setHours(hour, 0),
      startHour: hour,
      endHour: hour + 1, // par exemple, fin une heure après le début
      endDate: new Date(day).setHours(hour + 1, 0),
      title: 'Nouvel événement',
      description: 'Description de l\'événement',
      color: '#CCCCCC', // couleur par défaut, à personnaliser
    };
    // Ajoutez le nouvel événement à la liste des événements
    this.events.push(newEvent);
  },

  fetchevents() {
    axios.get('http://13.49.196.22/events/', {
        headers: {
            'Authorization' : localStorage.getItem('token')
        }
    })
    .then(response => {
        const doctorId = localStorage.getItem('id');
    console.log('doctorId', doctorId);
        this.events = response.data ;

    })
    .catch(error => {
        console.error('There was an error!', error);
    });
},

   

    // Add Event 

    addEvent() {
    const medecin = localStorage.getItem('id');
    const debut = moment.utc(this.eventStartDate + 'T' + this.eventStartTime + ':00Z').tz('Europe/Lisbon').format();
    const fin = moment.utc(this.eventEndDate + 'T' + this.eventEndTime + ':00Z').tz('Europe/Lisbon').format();
    const description = this.eventDescription;
    const client = this.eventPatient;
    const color = this.eventColor;
    const title = this.eventTitle;
    const patientId = this.newPatient ? null : this.eventPatient;


    axios.post('http://13.49.196.22/events/', {
        medecin,
        debut,
        fin,
        description,
        client,
        color,
        title,
        patientId
    }, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(response => {
        console.log('Event added successfully', response);
        this.fetchevents();
        this.showModal = false;
    })
    .catch(error => {
        console.error('There was an error!', error);
    });
}
,

    // Update Event

    updateEvent() {
        event.preventDefault();
    const debut = this.eventStartDate + 'T' + this.eventStartTime + ':00Z';
    const fin = this.eventEndDate + 'T' + this.eventEndTime + ':00Z';
    const description = this.eventDescription;
    const client = this.eventPatient;
    const color = this.eventColor;
    const title = this.eventTitle;

    console.log('eventID', this.eventID);

    axios.put(`http://13.49.196.22/events/${this.eventID}`, {
        start: debut,
        end: fin,
        description,
        client,
        color,
        title,
    }, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(response => {
        console.log('Event updated successfully', response);
        this.fetchevents();
        this.showEditModal = false;
    })
    .catch(error => {
        console.error('There was an error!', error);
    });
},

    // Delete Event

    deleteEvent() {
    axios.delete(`http://13.49.196.22/events/${this.eventID}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(response => {
        console.log('Event deleted successfully', response);
        this.fetchevents();
        this.showEditModal = false;
    })
    .catch(error => {
        console.error('There was an error!', error);
    });
},


  


  

        

        isCurrentDay(index) {
    const currentDayOfWeek = new Date().getDay();
    return index === currentDayOfWeek;
  },

  previousDay() {
      if (this.currentDay === 0) {
        // Si c'est le premier jour de la semaine, passer à la semaine précédente
        this.currentWeek--;
        this.currentDay = this.daysPerWeek - 1; // Dernier jour de la semaine
      } else {
        this.currentDay--; // Décrémenter le jour actuel
      }
    },
    nextDay() {
      if (this.currentDay === this.daysPerWeek - 1) {
        // Si c'est le dernier jour de la semaine, passer à la semaine suivante
        this.currentWeek++;
        this.currentDay = 0; // Premier jour de la semaine suivante
        this.nextWeek();
      } else {
        this.currentDay++; // Incrémenter le jour actuel
      }
    },

    openModal(event) {
  // Mettre à jour les données de la modal avec les informations de l'événement
  console.log('event', event);
  this.eventID = event._id;
  this.eventTitle = event.title;
  this.eventDescription = event.description;
  
  // Convertir les dates au format approprié
  this.eventStartDate = new Date(event.start).toISOString().split('T')[0];
  this.eventStartTime = new Date(event.start).toISOString().split('T')[1].slice(0, 5);

  this.eventEndDate = new Date(event.end).toISOString().split('T')[0];
  this.eventEndTime = new Date(event.end).toISOString().split('T')[1].slice(0, 5);

  this.eventColor = event.color;
  this.eventPatient = event.patientId; // Assurez-vous que event.patientId contient l'ID du patient

  this.showEditModal = true;
}
,
        

        openEventForm(hour) {
            // Lorsque l'utilisateur clique sur une cellule de l'heure, ouvrez le formulaire de création d'événement
            this.selectedDay = this.days[this.currentDay];
            this.startHourEvent = hour;
            this.endHourEvent = hour + 1; // Durée par défaut d'une heure
            this.isFormVisible = true;

        },

        countEventsByDay(day) {
    // Filtrer les événements qui ont lieu pendant la journée spécifiée
    const eventsForDay = this.events.filter(event => {
        // Extraire le jour de la date de début de l'événement
        const eventDay = new Date(event.start).getDate();
        // Retourner true si l'événement a lieu le jour spécifié
        return eventDay === day;
    });
    // Retourner le nombre d'événements pour ce jour
    return eventsForDay.length;
}
,

        getEventsCountForDay(day) {
    // Utilisez la méthode eventsForDayAndHour pour récupérer les événements pour une journée donnée
    const eventsForDay = this.eventsForDayAndHour(day, 0); // Utilisez l'heure 0 pour récupérer tous les événements pour la journée entière
    return eventsForDay.length;
  },
        updateCurrentTime() {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();
            const pixelsPerMinute = 60; // Ajustez cette valeur selon votre design
            if (currentHour >= this.startHour && currentHour <= this.endHour) {
                this.currentTimePosition = ((currentHour - this.startHour) * 60 + currentMinute) * pixelsPerMinute / 60;
            } else {
                this.currentTimePosition = -1; // Cacher la barre de temps si en dehors de la plage horaire choisie
            }
        },

        eventsForDayAndHour(day, hour) {
    return this.events.filter(event => {
        const eventStartDate = new Date(event.start);
        const eventEndDate = new Date(event.end);
        const eventDay = eventStartDate.toLocaleDateString('pt-PT', { weekday: 'short', month: 'short', day: 'numeric' });
        const eventStartHour = eventStartDate.getHours();
        const eventStartMinute = eventStartDate.getMinutes();
        const eventEndHour = eventEndDate.getHours();
        const eventEndMinute = eventEndDate.getMinutes();
        return day === eventDay &&
               (hour > eventStartHour || (hour === eventStartHour && 0 >= eventStartMinute)) &&
               (hour < eventEndHour || (hour === eventEndHour && 0 < eventEndMinute));
    });
}
,
eventsForHour(day, hour) {
    // Filtrer les événements pour une heure spécifique et un jour spécifique
    return this.events.filter(event => {
        const eventStartDate = new Date(event.start);
       
        const eventDay = eventStartDate.toLocaleDateString('pt-PT', { weekday: 'short', month: 'short', day: 'numeric' });
        console.log(eventStartDate,eventDay )
        const eventStartHourUTC = eventStartDate.getUTCHours(); // Obtenez l'heure UTC de l'événement


        // Vérifier si l'heure spécifique correspond à l'heure de début de l'événement (heure UTC)
        return day === eventDay && hour === eventStartHourUTC;
    });
},



previousMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    },



    calculateEventTop(event) {
        const eventStartDate = new Date(event.start);
        const eventHour = eventStartDate.getHours();
        const eventMinute = eventStartDate.getMinutes();
        const pixelsPerMinute = 2; // Ajustez selon votre mise en page
        const top = (eventHour - this.startHour) * 120 + eventMinute;
        return top * pixelsPerMinute;
    },
    

   calculateEventHeight(event) {
    const eventStartDate = new Date(event.start);
    const eventEndDate = new Date(event.end);
    const eventDuration = (eventEndDate - eventStartDate) / (1000 * 60); // Durée de l'événement en minutes
   
    const pixelsPerMinute = 1; // Ajustez selon votre mise en page
    const pixelsPerRow = 120; // Hauteur de chaque ligne en pixels
    const height = eventDuration * pixelsPerMinute * (pixelsPerRow / 60); // Calcul de la hauteur de l'événement

    return height;
},

        addEventTomorrow() {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1); // Ajoute 1 jour à la date actuelle pour obtenir demain
            
            this.events.push({
                startDate: new Date(tomorrow.setHours(10, 0)), // Demain à 10h00
                endDate: new Date(tomorrow.setHours(12, 0)), // Demain à 12h00
                title: 'Réunion demain',
                description: 'Réunion avec l\'équipe de développement demain',
                color: '#FF5733'
            });
        },
       
    // Méthode pour mettre à jour les informations de la semaine
    updateWeekInfo() {
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);
        this.startDate = start.toLocaleDateString('pt-PT', { month: 'short', day: 'numeric' });
        this.endDate = end.toLocaleDateString('pt-PT', { month: 'short', day: 'numeric' });
        this.month = start.toLocaleDateString('pt-PT', { month: 'long' });
        this.year = start.getFullYear();
    },
    submitEvent() {
  const newEvent = {
    id: uuidv4(), // Génération de l'identifiant unique
    title: this.eventTitle,
    description: this.eventDescription,
    color: this.eventColor,
    startDate: new Date(this.eventStartDate + 'T' + this.eventStartTime),
    endDate: new Date(this.eventStartDate + 'T' + this.eventEndTime)
  };

  this.events.push(newEvent);



  // Réinitialiser les champs du formulaire
  this.eventTitle = '';
  this.eventDescription = '';
  this.eventColor = '#CCCCCC';
  this.eventStartDate = '';
  this.eventStartTime = '';
  this.eventEndTime = '';

  // Masquer le formulaire après soumission
  this.isFormVisible = false;
},

        getDateForDay(dayIndex) {
            const today = new Date();
            const diff = dayIndex - today.getDay();
            const date = new Date(today);
            date.setDate(date.getDate() + diff);
            return date.toLocaleDateString('pt-PT', { weekday: 'short', month: 'short', day: 'numeric' });
        },
        selectOption(option) {
            this.selectedOption = option;
        },
        showForm() {
            this.isFormVisible = true;
        },
       
      
toggleCard() {
      this.showCard = !this.showCard;
    },


getCurrentWeekDates() {
   
    const today = new Date();
    const currentDay = today.getDay(); // Récupérer le jour de la semaine actuel (0 = dimanche, 1 = lundi, ..., 6 = samedi)
    const startDate = new Date(today);
    const endDate = new Date(today);

    // Déterminer le début de la semaine (dimanche)
    startDate.setDate(startDate.getDate() - currentDay);
    // Déterminer la fin de la semaine (samedi)
    endDate.setDate(endDate.getDate() + (6 - currentDay));

    const weekDates = [];

    // Boucle pour générer les dates de chaque jour de la semaine
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);
        weekDates.push(currentDate.toLocaleDateString('pt-PT', { weekday: 'short', month: 'short', day: 'numeric' }));
    }

    this.days = weekDates;

    return weekDates;
},
nextWeek() {
    // Avancer d'une semaine
    this.currentWeekStartDate.setDate(this.currentWeekStartDate.getDate() + 7);
    const nextWeekStartDate = new Date(this.currentWeekStartDate);
    const nextWeekEndDate = new Date(this.currentWeekStartDate);
    nextWeekEndDate.setDate(nextWeekEndDate.getDate() + 6); // Fin de la semaine suivante

    const nextWeekDates = [];

    // Boucle pour générer les dates de chaque jour de la semaine suivante
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(nextWeekStartDate);
        currentDate.setDate(currentDate.getDate() + i);
        nextWeekDates.push(currentDate.toLocaleDateString('pt-PT', { weekday: 'short', month: 'short', day: 'numeric' }));
    }

    this.days = []; // Réinitialiser les dates

    // Utilisation de push pour ajouter les nouvelles dates à this.days
    nextWeekDates.forEach(date => {    
        this.days.push(date);
    });

    // Mettre à jour currentDay pour le jour actuel de la nouvelle semaine
    this.currentDay = new Date().getDay(); // Mettre à jour avec le jour actuel de la nouvelle semaine


    return nextWeekDates;
},


previousWeek() {
     // Avancer d'une semaine
     this.currentWeekStartDate.setDate(this.currentWeekStartDate.getDate() - 7);
    const nextWeekStartDate = new Date(this.currentWeekStartDate);
    const nextWeekEndDate = new Date(this.currentWeekStartDate);
    nextWeekEndDate.setDate(nextWeekEndDate.getDate() - 6); // Fin de la semaine suivante

    const nextWeekDates = [];

    // Boucle pour générer les dates de chaque jour de la semaine suivante
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(nextWeekStartDate);
        currentDate.setDate(currentDate.getDate() + i);
        nextWeekDates.push(currentDate.toLocaleDateString('pt-PT', { weekday: 'short', month: 'short', day: 'numeric' }));
    }

    this.days = []; // Réinitialiser les dates

    // Utilisation de push pour ajouter les nouvelles dates à this.days
    nextWeekDates.forEach(date => {
        
        this.days.push(date);
    });


    return nextWeekDates;
},

// add event push this.events with data from the form


    

    }
};
</script>


<style scoped>
.calendar {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    z-index: 1;
}

.header {
    display: flex;
}

.hour-column {
    width: 50px;
    /* Adjust width as needed */
    border-right: 1px solid #ccc;
}

.day-column {
    flex: 1;
    border-bottom: 1px solid #ccc;
    text-align: center;
    height: 50px;
    /* Adjust height as needed */
}

.body {
    position: relative;
    /* Add this line */
    flex-direction: row;
}

.hour-row {
    display: flex;
    border-bottom: 1px solid rgba(204, 204, 204, 0.42);
    height: 120px;
}

.hour {
    width: 50px;
    /* Adjust width as needed */
    border-right: 1px solid #ccc;
    text-align: center;
}

.event-cell {
    flex: 1;
    border-right: 1px solid #ccc;
}

.current-time-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    /* Adjust height as needed */
    background-color: #1463F3;
    /* Adjust color as needed */
}

.event {
    border-radius: 5px;
    width: 90%;
    padding: 5px;

}

.card_calendar {
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 88vh;
}

.current_cell {
    background-color: rgba(19, 99, 244, 0.11);
    /* Couleur pour le jour actuel */
}


.current-day {
    background-color: rgba(19, 99, 244, 0.11);
    /* Couleur pour le jour actuel */
    color: #1463F3;
    font-weight: 900;
    border-bottom: 2px solid #1463F3;
}

.span_current_week {
    background-color: white;
    border: 1px solid #ccc;
    color: black;
    font-weight: 500;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.calendar-selector {
    display: flex;
    justify-content: space-around;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 10px;
}

.selector-option {
    cursor: pointer;
    padding: 0px 10px;
}

.selected {
    background-color: #ffffff;
}
.card-calendar-container {
  position: relative;
  display: inline-block;
  width: fit-content;
}


.card {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* Ajustez la largeur de la carte selon vos besoins */
  height: 100%; /* Prendra la hauteur du conteneur parent */
  background-color: white;
  z-index: 1000;
  overflow: hidden;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

.td_cell {
    font-size: 12px;
    height: 100px;
}

.CountEvent {
    font-size: 15px;
    background-color: rgba(19, 99, 244, 0.11);
    color: white;
    font-weight: 200;
    border-radius: 8px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 5px; 
    text-align: center;
}


.side-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour l'effet d'ombrage */
    width: 0; /* Commence avec une largeur de 0 */
    overflow-x: hidden;
    transition: width 0.5s; /* Animation de transition de la largeur */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.side-panel.open {
    width: 50%; /* Ouverture jusqu'à 50% de la largeur */
}

.panel-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%; /* Largeur du contenu */
    background-color: white; /* Couleur de fond du volet */
}

.color-picker {
    display: flex;
    margin-top: 5px;
}

.color-option {
    width: 20px;
    height: 20px;
    border: none;
    margin-right: 5px;
    cursor: pointer;
}


.blurred {
      filter: blur(5px);
      pointer-events: none; /* Empêcher les interactions sur le fond flou */
      overflow: hidden;
    }


</style>