<template>
    <div class="reset-password">
      <h1>Réinitialisation du mot de passe</h1>
      <div v-if="!resetSuccess" class="reset-form">
        <p>Entrez votre nouveau mot de passe :</p>
        <input type="password" v-model="password" placeholder="Nouveau mot de passe" />
        <input type="password" v-model="confirmPassword" placeholder="Confirmer le nouveau mot de passe" />
        <button @click="resetPassword">Réinitialiser le mot de passe</button>
        <p v-if="resetError" class="error">{{ resetErrorMessage }}</p>
      </div>
      <div v-else class="success-message">
        <p>{{ resetSuccessMessage }} <router-link :to="'/'">Retour a l'accueil</router-link> </p>
        <router-link to="/login">Se connecter</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        password: '',
        confirmPassword: '',
        resetSuccess: false,
        resetSuccessMessage: '',
        resetError: false,
        resetErrorMessage: '',
        token: this.$route.params.token 
      };
    },
    methods: {
      async resetPassword() {
        if (this.password !== this.confirmPassword) {
          this.resetError = true;
          this.resetErrorMessage = 'Les mots de passe ne correspondent pas.';
          return;
        }
  
        try {
          // Effectuer une requête pour réinitialiser le mot de passe avec le token
          const response = await fetch(`http://13.49.196.22/resetpassword/${this.token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              password: this.password
            })
          });
  
          if (response.ok) {
            const data = await response.json();
            this.resetSuccess = true;
            this.resetSuccessMessage = data.message;
            
          } else {
            const data = await response.json();
            this.resetError = true;
            this.resetErrorMessage = data.message;
          }
        } catch (error) {
          console.error('Erreur lors de la réinitialisation du mot de passe :', error);
          this.resetError = true;
          this.resetErrorMessage = 'Erreur lors de la réinitialisation du mot de passe.';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .reset-password {
    max-width: 400px;
margin: auto;
text-align: center;
min-height: 500px;
margin-top: 100px;
margin-bottom: auto;
}

  
  .reset-form {
    margin-top: 20px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
  }
  
  .success-message {
    margin-top: 20px;
  }
  </style>
  