<template>
    <div class="rowlogin">
        <div  class="column">
            <div class="custom_padding" >
               


                <form v-if="resettingPassword">
                    <h2 class="mt-5" style="text-align: center;
    margin-bottom: 10px;
    font-size: 46px;
    font-weight: 600;"> Mot de Passe oublié ? </h2>
                <p style="    text-align: center;
    margin-bottom: 50px;" class="mt-2">Pas de soucis entrez votre email et nous vous enverrons un lien </p>
    <div class="form-group mb-4">
        <label for="resetEmail">Adresse email</label>
        <input style="height: 40px" type="email" class="form-control" id="resetEmail" v-model="resetEmail">
    </div>
    <button type="button" class="button_login mb-3"  @click="resetPassword">Réinitialiser le mot de passe</button>
    <p> <svg style="height: 17px; width: auto;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ccc" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg> <a style="cursor: pointer;" @click="resettingPassword = false">Revenir en arriere</a></p>

</form>

                <!-- Connexion -->


                

                <form v-else-if="loging">

                    <h2 class="mt-5" style="text-align: center;
    margin-bottom: 10px;
    font-size: 46px;
    font-weight: 600;"> Bienvenue </h2>
                <p style="    text-align: center;
    margin-bottom: 50px;" class="mt-2">Connectez-vous pour prendre un rendez-vous</p>

                    <div class="form-group mb-4">
                        <label for="exampleInputEmail1">Adresse email</label>
                        <input style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="email" class="form-control " id="exampleInputEmail1" aria-describedby="emailHelp" v-model="email">
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label for="exampleInputPassword1">Mot de passe</label>
                        <input style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="password" class="form-control " id="exampleInputPassword1" v-model="password">
                        <a @click="resettingPassword = true" href="#" class="mt-2 d-flex justify-content-end">Mot de passe oublié ?</a>
                    </div>
                    <button style=" margin-top: 150px;" type="button" class="button_login" @click="login()">Se connecter</button>
                   
                </form>
                


                <!-- Inscription -->
                <form v-else>
                    <h2 class="mt-5" style="text-align: center;
    margin-bottom: 10px;
    font-size: 46px;
    font-weight: 600;"> Inscription </h2>
                <p style="    text-align: center;
    margin-bottom: 50px;" class="mt-2">Remplissez les champs pour vous inscrire</p>
                    <div class="progress-bar">
            <div class="progress-bar-inner" :style="{ width: progress + '%' }"></div>
        </div>
        <div v-if="step  === 1" class="form-group">
                <label for="nom" class="form-label">Nom</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="text" id="nom" v-model="Nom" class="form-input">
            </div>
            <div v-if="step  === 2" class="form-group">
                <label for="prenom" class="form-label">Prénom</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="text" id="prenom" v-model="Prenom" class="form-input">
            </div>
            <div v-if="step  === 3" class="form-group">
                <label for="taille" class="form-label">Taille en cm</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="number" id="taille" v-model="Taille" class="form-input">
            </div>
            <div v-if="step  === 4" class="form-group">
                <label for="dateNaissance" class="form-label">Date de naissance</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="date" id="dateNaissance" v-model="DateNaissance" class="form-input">
            </div>
            <div v-if="step  === 5" class="form-group">
                <label for="sexe" class="form-label">Sexe</label>
                <select id="sexe" v-model="Sexe" class="form-input">
                    <option value="Homme">Homme</option>
                    <option value="Femme">Femme</option>
                </select>
            </div>
            <div v-if="step  === 8" class="form-group">
                <label for="email" class="form-label"> Email</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="email" id="email" v-model="email" class="form-input">
                <label for="password" class="form-label ">Mot de passe</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="password" id="password" v-model="password" class="form-input">
            </div>
            <div v-if="step  === 7" class="form-group">
                <label for="phoneCode" class="form-label">Indicatif téléphonique</label>
                <select id="phoneCode" v-model="phoneCode" class="form-input">
                    <option value="+33">+33 France</option>
                    <option value="+1">+1 USA</option>
                    <!-- Ajouter d'autres indicatifs au besoin -->
                </select>
            </div>
            <div v-if="step  === 7" class="form-group">
                <label for="phoneNumber" class="form-label">Numéro de téléphone</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="tel" id="phoneNumber" v-model="phoneNumber" class="form-input">
            </div>
            <div v-if="step  === 6" class="form-group" style="position: relative;">
                <label for="address" class="form-label">Adresse</label>
                 <input  style="background-color: #F5F5F7;
    border: none;
    height: 40px;" type="text" id="address" v-model="address" class="form-input" @input="fetchAddressSuggestions">
                <div v-if="addressSuggestions.length" class="suggestions">
                    <div v-for="suggestion in addressSuggestions" :key="suggestion.id" @click="selectAddress(suggestion)">
                        {{ suggestion.place_name }}
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between mb-3">
                <button type="button" class="button-secondary" @click="prevStep" v-if="step > 1">Précédent</button>
                <button type="button" class="button-primary" @click="nextStep" v-if="step < 8">Suivant</button>
                <button type="button" class="button-primary" @click="register" v-if="step === 8">S'inscrire</button>
            </div>

</form>



<div v-if="loging && resettingPassword === false ">
            <p>Pas de compte ? <a style="cursor: pointer; font-weight: 600; color: #007bff;" @click="loging = !loging">Inscrivez-vous</a></p>
        </div>
        <div v-else-if="loging  === false && resettingPassword === false  ">
            <p>Déjà un compte ? <a style="cursor: pointer; font-weight: 600; color: #007bff;" @click="loging = !loging">Connectez-vous</a></p>
        </div>

            </div>
        </div>

        <div class="column d-none d-md-block">
            <img style="min-height: 700px;" class="image_right" src="../assets/doctor-nurses-special-equipment_23-2148980721.avif" alt="appointment">
        </div>
    </div>

    
</template>

<script>
import axios from 'axios'

export default {
    name: 'LoginView',
    data() {
        return {
            email: '',
            step: 1,
            password: '',
            loging: true,
            Nom: '',
            Prenom: '',
            Taille: '',
            DateNaissance: '',
            Sexe: '',
            phoneCode: '+33',
            phoneNumber: '',
            address: '',
            addressSuggestions: [],
            selectedAddress: null,
            street: '',
            postalCode: '',
            city: '',
            resettingPassword: false, // Ajout de la variable resettingPassword
        resetEmail: '', // Variab
        }
    },
    methods: {
        // Connexion
        login() {
    axios.post('http://13.49.196.22/login', {
        email: this.email,
        password: this.password
    })
    .then(response => {
        console.log(response.data);
        
        if (response.data.UserTYPE === 'patient') {
            // Patient logic
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userType', response.data.UserTYPE);
            localStorage.setItem('nom', response.data.nom);
            localStorage.setItem('prenom', response.data.prenom);
            localStorage.setItem('patientId', response.data.patientId);
            this.$router.push('/');
        } else {
            // Doctor logic
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('id', response.data.id);
            console.log('id', response.data.id);
            this.$router.push({ name: 'admin' });
        }
    })
    .catch(error => {
        console.log(this.email);
        console.log(this.password);
        window.alert('Email ou mot de passe incorrect');
        console.log(error);
    });
}
,
        nextStep() {
                    if (this.step < 10) {
                        this.step++;
                    }
                },
                prevStep() {
                    if (this.step > 1) {
                        this.step--;
                    }
                },

        async resetPassword() {
        try {
          const response = await axios.post('http://13.49.196.22/reset-password', {
            email: this.resetEmail
          });
          if (response.status === 200) {
            this.requestSuccess = true;
            this.resetEmail = '',
            this.requestSuccessMessage = response.data.message;
          } else {
            this.requestError = true;
            this.requestErrorMessage = response.data.message;
          }
        } catch (error) {
          console.error('Erreur lors de la demande de réinitialisation du mot de passe :', error);
          this.requestError = true;
          this.requestErrorMessage = 'Erreur lors de la demande de réinitialisation du mot de passe.';
        }
      },
        // Inscription
        register() {
            // Si aucune adresse n'est sélectionnée, sélectionner la première suggestion
            if (!this.selectedAddress && this.addressSuggestions.length > 0) {
                this.selectAddress(this.addressSuggestions[0]);
            }

            // Vérifiez que tous les champs requis sont remplis avant d'envoyer le formulaire
            if (this.isValidForm()) {
                axios.post('http://13.49.196.22/signup/patient', {
                    email: this.email,
                    password: this.password,
                    nom: this.Nom,
                    prenom: this.Prenom,
                    sex: this.Sexe,
                    taille: this.Taille,
                    date_de_naissance: this.DateNaissance,
                    adresse: this.street,
                    city: this.city,
                    code_postal: this.postalCode,
                    numero: `${this.phoneCode} ${this.phoneNumber}`
                })
                .then(response => {
                    console.log(response.data)
                    localStorage.setItem('token', response.data.token)
                    this.$router.push('/dashboard')
                })
                .catch(error => {
                    console.log(error)
                })
            } else {
                console.log("Veuillez remplir tous les champs requis");
            }
        },
        async fetchAddressSuggestions() {
            const accessToken = 'pk.eyJ1IjoiZW56b3YiLCJhIjoiY2x3dDhuZGtxMDE3dDJqc2F0dHRnMGhocyJ9.w4ghuWWoBjSzHyHKHx8R9A';
            const query = this.address;
            if (query.length > 2) {
                const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${accessToken}&country=FR`);
                const data = await response.json();
                this.addressSuggestions = data.features;
            } else {
                this.addressSuggestions = [];
            }
        },
        selectAddress(suggestion) {
            this.address = suggestion.place_name;
            this.selectedAddress = suggestion;
            this.addressSuggestions = [];

            // Extraire les informations de la suggestion sélectionnée
            const context = suggestion.context;

            // Extraire la rue et le numéro
            const streetContext = suggestion.place_name.split(',')[0];

            // Extraire la ville et le code postal
            const cityContext = context.find(ctx => ctx.id.startsWith('place'));
            const postalCodeContext = context.find(ctx => ctx.id.startsWith('postcode'));

            this.street = streetContext || '';
            this.city = cityContext ? cityContext.text : '';
            this.postalCode = postalCodeContext ? postalCodeContext.text : '';
        },
        isValidForm() {
            return this.Nom && this.Prenom && this.Taille && this.DateNaissance && this.Sexe &&
                   this.email && this.password && this.phoneCode && this.phoneNumber && this.street;
        }
    },
    computed: {
                progress() {
                    return (this.step / 8) * 100;
                }
            },
}
</script>


<style>

.image_right{
    width: 100%;
    height: 100%;
    border-radius:10px;
}

.form-group{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}



.button_login{
    border-radius: 5px;
    background-color: rgb(13, 77, 195); 
    color: white;
    border: none;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;

}

.checkbox{
   margin-right: 7px;

}

.form_login{
    margin-left: auto;
    margin-right: auto;
    width: 90% !important ;
}

.suggestions {
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    position: absolute;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
}

.suggestions div {
    padding: 8px;
    cursor: pointer;
}

.suggestions div:hover {
    background-color: #f0f0f0;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.rowlogin:after {
  content: "";
  display: table;
  clear: both;
}

.hr-with-text {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 20px 0;
        }
        .hr-with-text::before,
        .hr-with-text::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #000;
        }
        .hr-with-text:not(:empty)::before {
            margin-right: .25em;
        }
        .hr-with-text:not(:empty)::after {
            margin-left: .25em;
        }

        .progress-bar {
            width: 100%;
            background-color: #f3f3f3;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 20px;
        }
        .progress-bar-inner {
            height: 10px;
            background-color: rgb(38 114 254);
            width: 0%;
            transition: width 0.3s ease-in-out;
        }
        .form-group {
            margin-bottom: 15px;
        }
        .form-label {
            display: block;
            margin-bottom: 5px;
        }
        .form-input {
            width: 100%;
            padding: 8px;
            margin-top: 5px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
        .button-primary {
            background-color: #007bff;
            color: white;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
        .button-primary:hover {
            background-color: #0056b3;
        }
        .button-secondary {
            background-color: #6c757d;
            color: white;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
        .button-secondary:hover {
            background-color: #5a6268;
        }

        .custom_padding{
            padding-left: 50px;
    padding-right: 50px;
        }


        @media screen and (max-width: 768px) {
  .column {
    width: 100%; /* Changer la largeur à 100% pour occuper toute la largeur */
    float: none; /* Supprimer le float pour afficher en bloc */
  }
  .custom_padding{
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>