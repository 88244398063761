<template>

    <div class="mt-5 mb-3">
        <div class="back-link">
      <a href="#" @click.prevent="goBack">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"/>
        </svg>
        Liste Patient
      </a>
    </div>
      <div class="row">
        <!-- Première carte -->
        <div class="col-md-3 d-flex align-items-center">
          <div class="custom-card flex-fill">
            <div class="card-body text-center d-flex flex-column justify-content-center">
              <div class="profile-pic-container mx-auto">
                <div class="profile-pic">
                  {{ getInitial(this.patients.firstName) }}  {{ getInitial(this.patients.lastName) }}
                </div>
              </div>
              <p style=" font-size: 18px;" class="mt-3 mb-0 poppins-semibold">{{ this.patients.firstName }} {{ this.patients.lastName }}</p>
              <p style="font-size: 12px; color: rgb(158, 154, 154);" class="mb-3 poppins-regular">{{ this.patients.email }}</p>
              <div class="row">
                <div class="col-6">
  <label class="poppins-regular label_card">Visite</label> <br>
  <span style="font-size: 25px;" class="poppins-bold">{{ countAppointments().totalAppointments }}</span>
</div>
<div class="col-6">
  <label class="poppins-regular label_card">Annulations</label> <br>
  <span style="font-size: 25px; " class="poppins-bold">{{ countAppointments().cancelledCount }}</span>
</div>

              </div>
              <button style="margin-top: 20px; border: none; padding: 7px 15px; border-radius: 10px; font-weight: 600;" @click="sendEmail">
  Envoyer un Message
</button>
            </div>
          </div>
        </div>
  
        <!-- Deuxième carte -->
        <div class="col-md-4 d-flex align-items-center">
          <div class="custom-card flex-fill">
            <h2 style="align-items: center; margin: 2px 10px 5px; " class="card-title d-flex justify-content-center">
                <span style="font-size: 20px;" class="span_card poppins-semibold">Informations Personnelles</span>
              </h2>
            <div class="card-body d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-4">
                    <label class="poppins-regular label_card">Gender</label> <br>
                    <span class="span_card poppins-semibold">{{this.patients.sexe}}</span>
                  </div>
                  <div class="mb-4">
                    <label class="poppins-regular label_card">Ville</label> <br>
                    <span class="span_card poppins-semibold">{{this.patients.ville}}</span>
                  </div>
                  <div class="mb-4">
                    <label class="poppins-regular label_card">Code Postal</label> <br>
                    <span class="span_card poppins-semibold">{{this.patients.code_postal}}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-4">
                    <label class="poppins-regular label_card">Date de naissance</label> <br>
                    <span class="span_card poppins-semibold">{{this.patients.date_de_naissance}}</span>
                  </div>
                  <div class="mb-4">
                    <label class="poppins-regular label_card">Numero</label> <br>
                    <span class="span_card poppins-semibold">{{this.patients.numero}}</span>
                  </div>
                  <div class="mb-4">
                    <label class="poppins-regular label_card">Addresse</label> <br>
                    <span class="span_card poppins-semibold">{{this.patients.adresse}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Troisième carte -->
        <div class="col-md-5 d-flex align-items-center">
  <div class="custom-card flex-fill">
    <h2 style="align-items: center; margin: 2px 10px;" class="card-title d-flex justify-content-between">
      <span style="font-size: 20px;" class="span_card poppins-semibold">Report</span>
      <a style="font-size: 13px; color: rgb(4, 56, 115); cursor: pointer;" @click="showUploadForm = !showUploadForm" class="span_card poppins-regular">Add a report</a>
    </h2>
    <div class="card-body d-flex flex-column">
      <form v-if="showUploadForm" @submit.prevent="uploadReport" enctype="multipart/form-data">
        <input type="file" name="file" ref="fileInput" multiple />
        <button type="submit">Upload</button>
      </form>
      <template v-if="files.length === 0">
        <p>Aucun fichier</p>
      </template>
      <table v-if="files.length > 0" style="margin-top: 15px; margin-bottom: 0;" class="table">
        <tbody>
          <tr v-for="(file, index) in paginatedFiles" :key="index">
            <td>{{ file.originalname }}</td>
            <td>{{ formatDate(file.uploadDate) }}</td>
            <td>
              <button style="border: none; background-color: #fff;" @click="downloadReport(file)">
                <svg style="height: 15px; width: 15px; fill: rgb(158, 154, 154);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="files.length > 3" class="pagination">
        <button @click="prevFilePage" :disabled="currentFilePage === 1">Previous</button>
        <span>{{ currentFilePage }} / {{ totalFilePages }}</span>
        <button @click="nextFilePage" :disabled="currentFilePage === totalFilePages">Next</button>
      </div>
    </div>
  </div>
</div>



      </div>
      
      <!-- Texte "Rendez-vous" et carte des filtres et du tableau -->
      <div class="mt-5">
    <h3 style="text-align: left; margin: 10px;" class="poppins-regular">Rendez-vous</h3>
    <div class="custom-card">
      <div class="card-body">
        <div class="d-flex mb-2 appointment-filters">
          <span :class="{ active: activeFilter === 'all' }" @click="filterAppointments('all')">All</span>
          <span :class="{ active: activeFilter === 'upcoming' }" @click="filterAppointments('upcoming')">Upcoming</span>
          <span :class="{ active: activeFilter === 'past' }" @click="filterAppointments('past')">Past</span>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Service</th>
              <th>Statuts</th>
              <th>Heure</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(appointment, index) in paginatedAppointments" :key="index">
              <td>{{ formatDate(appointment.start) }}</td>
              <td>{{ appointment.service }}</td>
              <td>{{ appointment.status }}</td>
              <td>{{ formatHour(appointment.start) }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="paginatedAppointments.length > 5" class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
          <span>Page {{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
      </div>
    </div>
  </div>
    </div>
  </template>
  
  
  <script>
import axios from 'axios'
import moment from 'moment';
  export default {

    data() {
      return {
        visitCount: 10,
        showUploadForm: false,
        reports: [],
        files: [],
        cancellationCount: 2,
        appointments: [],
        patients: [],
        filteredAppointments: [],
        activeFilter: 'all',
        currentPage: 1,
      itemsPerPage: 10,
      currentFilePage:1,
      itemsPerPageFiles: 4,
      
      };
    },
    mounted (){
      this.fetchPatients();
      this.fetchFiles();
    },
    computed: {
      paginatedAppointments() {
      const sortedAppointments = this.filteredAppointments.slice().sort((a, b) => new Date(b.start) - new Date(a.start));
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedAppointments.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredAppointments.length / this.itemsPerPage);
    },
    totalFilePages() {
      return Math.ceil(this.files.length / this.itemsPerPageFiles);
      
    },
    paginatedFiles() {
    const startIndex = (this.currentFilePage - 1) * this.itemsPerPageFiles;
    const endIndex = startIndex + this.itemsPerPageFiles;
    return this.files.slice(startIndex, endIndex);
  },
  },
    methods: {
      prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        
      }
    },
    prevFilePage() {
    if (this.currentFilePage > 1) {
      this.currentFilePage--;
    }
  },
  nextFilePage() {
    if (this.currentFilePage < this.totalFilePages) {
      this.currentFilePage++;
    }
  },

        goBack(){
            this.$router.push('/admin/patient');
        },
        sendEmail() {
    const subject = "Sujet de votre e-mail";
    const body = "Contenu de votre e-mail";
    const mailtoLink = `mailto:${this.patients.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  },

      deleteReport(index) {
        this.reports.splice(index, 1);
      },
      formatDate(date) {
      return moment(date).format('DD/MM/YYYY'); // Formatage de la date
    },
    formatHour(date) {
  return moment(date).format('HH:mm'); // Formatage de l'heure
},
      async downloadReport(file) {
      const token = localStorage.getItem('token');
      const patientId = this.$route.params.id; // Remplacez ceci par la logique pour obtenir l'ID du patient dynamiquement

      try {
        const response = await axios({
          url: `http://13.49.196.22/doctor/download/${patientId}/${file.filename}`,
          method: 'GET',
          responseType: 'blob', // Important pour télécharger les fichiers binaires
          headers: {
            Authorization: `${token}`
          }
        });

        const blob = new Blob([response.data], { type: file.mimetype });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.originalname;
        link.click();
      } catch (error) {
        console.error('Erreur lors du téléchargement du fichier :', error);
        alert('Erreur lors du téléchargement du fichier');
      }
    },
      filterAppointments(filter) {
        this.activeFilter = filter;
   
        if (filter === 'all') {
          this.filteredAppointments = this.appointments;
          console.log('All : ',this.filteredAppointments)
        } else if (filter === 'upcoming') {
          // Logic for upcoming appointments
          this.filteredAppointments = this.appointments.filter(appointment => new Date(appointment.start) > new Date());
        } else if (filter === 'past') {
          // Logic for past appointments
          this.filteredAppointments = this.appointments.filter(appointment => new Date(appointment.start) < new Date());
        }
      },
      getInitial(name) {
        return name ? name.charAt(0) : '';
    },
      fetchPatients() {
        const token = localStorage.getItem('token');
        const patientId = this.$route.params.id;
        axios.get(`http://13.49.196.22/doctor/patient-info/${patientId}`, {
            headers: {
                Authorization: `${token}`
            }
        })
        .then((response) => {
            console.log(response.data);
            this.patients = response.data;
            this.fetchAppointments();
        })
        .catch((error) => {
            console.log(error);
        });
    },
    async fetchAppointments() {
  const token = localStorage.getItem('token');
  const patientId = this.$route.params.id;
  try {
    const response = await axios.get(`http://13.49.196.22/doctor/patient-appointments/${patientId}`, {
      headers: {
        Authorization: `${token}`
      }
    });
    this.appointments = response.data || [];
    console.log(this.appointments);
    this.filterAppointments(this.activeFilter);
  } catch (error) {
    console.error('Erreur lors de la récupération des rendez-vous du patient :', error);
    alert('Erreur lors de la récupération des rendez-vous du patient');
  }
},
countAppointments() {
  // Compter le nombre total de rendez-vous
  const totalAppointments = this.appointments.length;

  // Filtrer les rendez-vous annulés
  const cancelledAppointments = this.appointments.filter(appointment => appointment.status === 'annuler');

  // Compter le nombre de rendez-vous annulés
  const cancelledCount = cancelledAppointments.length;

  // Calculer le nombre de rendez-vous non annulés
  const activeAppointmentsCount = totalAppointments - cancelledCount;

  return { totalAppointments: activeAppointmentsCount, cancelledCount };
},

    async uploadReport() {
      const token = localStorage.getItem('token');
      const patientId = this.$route.params.id; // Change this to dynamically get the patient ID as needed
      const formData = new FormData();
      const files = this.$refs.fileInput.files;

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      try {
        const response = await axios.post(`http://13.49.196.22/doctor/upload/${patientId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`
          }
        });
        console.log(response.data);
        alert('Files uploaded successfully');
      } catch (error) {
        console.error('Error uploading files:', error);
        alert('Error uploading files');
      }
    },
    async fetchFiles() {
  const token = localStorage.getItem('token');
  const patientId = this.$route.params.id; // Remplacez ceci par la logique pour obtenir l'ID du patient dynamiquement

  try {
    const response = await axios.get(`http://13.49.196.22/doctor/files/${patientId}`, {
      headers: {
        Authorization: `${token}`
      }
    });

    // Vérifiez si la réponse contient des fichiers
    if (response.data.files && response.data.files.length > 0) {
      // Mettez à jour vos données avec les fichiers récupérés
      this.files = response.data.files;
    } else {
      // Si la réponse est vide, affectez un tableau vide à this.files
      this.files = [];
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des fichiers du patient :', error);
    alert('Erreur lors de la récupération des fichiers du patient');
  }
},


    async deleteFile(file) {
      const token = localStorage.getItem('token');
      const patientId = this.$route.params.id; // Remplacez ceci par la logique pour obtenir l'ID du patient dynamiquement

      try {
        const response = await axios.delete(`http://13.49.196.22/doctor/files/${patientId}/${file._id}`, {
          headers: {
            Authorization: `${token}`
          }
        });
        console.log(response.data);
        // Supprimez le fichier de la liste
        const index = this.files.findIndex(f => f._id === file._id);
        if (index !== -1) {
          this.files.splice(index, 1);
        }
        alert('Fichier supprimé avec succès');
      } catch (error) {
        console.error('Erreur lors de la suppression du fichier :', error);
        alert('Erreur lors de la suppression du fichier');
      }
    }
    },
    created() {
      this.filterAppointments('all');
    }
  };
  </script>
  

  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  
  template {
    font-family: 'Poppins', sans-serif;
  }
  
  .custom-card {
    background-color: #fff;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
    overflow: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px; /* Hauteur minimale pour les cartes */
  }
  
  .label_card {
    font-size: 12px;
    color: rgb(158, 154, 154);
  }
  
  .span_card {
    font-size: 15px;
  }
  
  .profile-pic-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ff010140; /* Couleur de fond claire */
    border: solid 1px red;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 3px #FFF; /* Crée un espace entre le fond et la bordure */
  }
  
  .profile-pic {
    font-size: 36px;
    color: #3c3a3a; /* Couleur du texte */
    font-weight: 600;
  }
  
  .table .bg-light {
    background-color: #0278ef; /* Couleur de fond gris clair */
  }
  
  .table td {
    vertical-align: middle;
  }
  
  .poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  
  /* Styles pour les filtres de rendez-vous */
  .appointment-filters span {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom-color 0.3s;
  }
  
  .appointment-filters span:hover {
    color: #007bff;
  }
  
  .appointment-filters .active {
    color: #007bff;
    border-bottom-color: #007bff;
    background-color: white; 
    border-radius: 0px;
  }

  .back-link {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .back-link a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(4, 56, 115);
    font-size: 18px;
  }
  .back-link svg {
    margin-right: 8px;
  }

  .pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.pagination button {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
  </style>
  
  