<template>
    <div>
      <h2>Demande de réinitialisation de mot de passe</h2>
      <form @submit.prevent="requestResetPassword">
        <div>
          <label for="email">Adresse e-mail :</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <button type="submit">Envoyer la demande de réinitialisation</button>
      </form>
      <div v-if="requestSuccess">{{ requestSuccessMessage }}</div>
      <div v-if="requestError">{{ requestErrorMessage }}</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
        requestSuccess: false,
        requestSuccessMessage: '',
        requestError: false,
        requestErrorMessage: ''
      };
    },
    methods: {
      async requestResetPassword() {
        try {
          const response = await axios.post('http://13.49.196.22/reset-password', {
            email: this.email
          });
          if (response.status === 200) {
            this.requestSuccess = true;
            this.requestSuccessMessage = response.data.message;
          } else {
            this.requestError = true;
            this.requestErrorMessage = response.data.message;
          }
        } catch (error) {
          console.error('Erreur lors de la demande de réinitialisation du mot de passe :', error);
          this.requestError = true;
          this.requestErrorMessage = 'Erreur lors de la demande de réinitialisation du mot de passe.';
        }
      }
    }
  };
  </script>
  
  
  <style scoped>
  /* Styles CSS spécifiques au composant RequestResetPassword.vue */
  </style>
  