<template>
    <div class="banner" >
    </div>

    <div class="row">
        <div class="col-md-2">
            <img class="profile_picture d-flex justify-content-start" src="../assets/pexels-photo-106399.jpeg" alt="profile picture" >
        </div>
        <div class="col-md-4 d-flex justify-content-start mt-2">
            <div>
                <h3 style="font-weight: 900;" >Dr. {{ this.doctor.nom }} {{ this.doctor.prenom }} </h3>
                <p style="text-align: left;" >
                    {{ this.doctor.specialite }} <br> {{ this.doctor.adresse }}, {{ this.doctor.ville }} 
                </p>
            </div>
           
        </div>

        
    </div>

    <div class="container mt-5 mb-5">
        <div class="row">
            <div class="col-md-6">

            <div class="mb-3" >
                <h2 class="subtitle" > A Propos </h2>
                <p class="paragraph" >
                    {{ this.doctor.description }}
                </p>
            </div>
            <div class="mb-3" >
                <h2 class="subtitle" >
                Contact
               </h2>
                <p class="paragraph" >
                    Email :  {{ this.doctor.email }} <br>
                    Téléphone : {{ this.doctor.tel }} <br>
                    Adresse : {{ this.doctor.adresse }}, {{ this.doctor.ville }}, {{ this.doctor.code_postal }}

                   

                </p>
            </div>

            <div class="mb-3" >
                <h2 class="subtitle" >
                    Horaires de travail
                </h2>
                <div class="card_horraire">

                        <div >
                            <div class="row">
                            <div class="col-md-6">
                                <p> Lundi </p>
                            <p> Mardi </p>
                            <p> Mercredi </p>
                            <p> Jeudi </p>
                            <p> Vendredi </p>
                            <p> Samedi </p>
                            <p> Dimanche </p>
                            <div>
                            </div>
                            </div>
                            <div class="col-md-6">
                            
                                <div v-for="horaire in this.doctor.horaire" :key="horaire._id" class="">
                                    <p> {{ horaire.debut}} - {{ horaire.fin }} </p>
                            <div>

                            </div>
                          
                        </div>
                        </div>


                
            </div>





            </div>
            </div>
            </div>
            </div>
            <div class="col-md-1">

            </div>


            <!-- Service avec Prix -->
            <div class="col-md-5">
                <div class="card_service">
                    <div v-for="service in this.doctor.service" :key="service._id">
                    <div class="d-flex justify-content-between">
                        
                            <p> {{ service.nom }} </p>
                        
                            <p> {{ service.prix }}€ </p>
                       
                        </div>

                       
                    </div>
                    
                    <div class="card_bottom">
                        <a @click="prendreRDV()" >
                            Prendre un rendez-vous
                        </a>
                </div>
                </div>
               
                </div>
            
        </div>

    </div>

  
    



</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            doctor: [],
        }
    },
    methods: {
        prendreRDV() {
            
            this.$router.push('/rdv/'+this.doctor._id)
        }
        
    },
    mounted() {
       const id = this.$route.params.id
        axios.get('http://13.49.196.22/doctors/'+id )
            .then(response => {
                this.doctor = response.data
                console.log(this.doctor)
            })
            .catch(error => {
                console.log(error)
                console.log(this.$route.params.id)
            })
    }
}

</script>

<style>

.banner {
    background-image: url('https://images.lamaisondestravaux.com/media/cache/post/rc/sS42ORS3/2022-04-28/de054c4fe13ade44ae13934bba1dad58.png'); ;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_picture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid white;
   position: relative;
   box-shadow: 0 0 20px 0 #435f7133;
    margin-top: -100px;
    margin-left: 10px;
}

.card_service {
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #435f7133;
    border: none;
    padding: 25px;
}

.card_bottom {
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #435f7133;
    background-color: rgb(13, 77, 195); 
    border: none;
    color: #ffffff;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 700;
}

.subtitle {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-align: left;
    margin-bottom: 40px;
    text-align: left;
}

.paragraph {
    font-size: 1em;
    margin: 0;
    padding: 0;
    font-weight: 400;
    text-align: left;
    margin-bottom: 40px;
    text-align: left;
}


.card_horraire {
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #435f7133;
    border: none;
    padding: 25px;
}

</style>