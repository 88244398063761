<template>

<!-- Header -->
   <!-- search Bar -->
   <div class="mt-5">
    <search-bar></search-bar>
   </div>

   <!-- End search Bar -->

   <!-- button Filter -->
   <div class="banner_filter">
    <div class="d-flex justify-content-between">
      <div>
        <label for="distance">Distance (en km)</label>
        <select v-model="distance" @change="updateDistance">
          <option value="5000">5km</option>
          <option value="10000">10km</option>
          <option value="20000">25km</option>
          <option value="50000">50km</option>
        </select>
      </div>
      <div> 
        <label for="availability">Disponibilité</label>
    <select id="availability" v-model="availability" @change="updateAvailability">
      <option value="">Tous</option>
      <option value="disponible">Disponible</option>
      <option value="non-disponible">Non Disponible</option>
    </select>
      </div>
    </div>
   
   
  </div>


   
   
  
   
   <!-- End Filter -->

   <!--Nombre de Resultat trouver-->
    
         <p style="color: #1463F3;"> {{this.number}} Resultats trouvés</p>

             <!-- For loop to show the doctors in two columns  -->
    <div class="displaynone" style="margin-left: 25px; margin-right: 25px; margin-top: 70px;">
        <div class="row">
            <div class="col-md-6" v-for="doc in paginatedDoctors" :key="doc._id">
                            <div class="card_doctor">
            <div class="row">
                <div class="col-md-4">
                    <img class="profile-logo2" :src="image" alt="appointment">
                </div>
                <div class="col-md-8">
                    <div><span style="font-size: large;" >{{ doc.nom }} {{ doc.prenom }}</span> <br> <span style="font-size: small; font-weight: 300;">{{ doc.specialite }}</span>  </div>
                
                    <p style="font-size: 14px;" >
                        {{ doc.description ? doc.description.slice(0, 100) : '' }}
                    </p>
                    <p style="padding: 5px; margin-top: 10px; margin-bottom: 2px;">
                          <img src="../assets/placeholder.png" style="height: 15px; width: 15px;" >  {{ doc.ville }} <br>
                          <img src="../assets/phone-call.png" style="height: 15px; width: 15px;" > {{ doc.tel }} 
                        </p>

                        <button @click="DetailsView(doc._id)" class="button-nav2">Prendre rendez-vous</button>
                </div>
               
                    
            </div>
        </div>
            </div>
        </div>
     </div>

<div  class="container mediatel ">
    <div  v-for="doc in paginatedDoctors" :key="doc._id" class="card_phone">
        <div @click="DetailsView(doc._id)" style="padding: 10px; margin-top: 10px;" class="  row">
        <div class="col-4" >
            <img class="profile-logo2" style="height: 100%; width: 100%;" :src="image" alt="appointment">
        </div>
        <div class="col-8">
            <h6>
            DR. {{ doc.nom }} {{ doc.prenom }} <br> <span style="font-size: small; font-weight: 300;">{{doc.specialite}}</span>
           </h6>
           <p style="    font-size: small;">
            {{ doc.description ? doc.description.slice(0, 30) : '' }}
            </p>
            <div class="d-flex justify-content-between">
                <div>
                    <img src="../assets/placeholder.png" style="height: 15px; width: 15px;" >   {{doc.ville}}
                </div>
               <div>
                <img src="../assets/phone-call.png" style="height: 15px; width: 15px;" >  {{ doc.tel }}
               </div>
               
            </div>
          
            
        </div>
     </div>
     </div>
</div>


    <!-- Pagination -->

    <div class=" justify-content-between  pagination-container">
    <button @click="prevPage"  :disabled="currentPage === 1">Précedent</button>
    <div>
        <button v-for="page in totalPage" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">{{ page }}</button>
    </div>
    <button @click="nextPage" :disabled="currentPage === totalPage">Suivant</button>
  </div>


</template>

<script>
import SearchBar from '../components/searchbarComponent.vue';
import axios from 'axios'

export default {
    name: 'ResultView',
    components: {
        'search-bar': SearchBar
    },
    data() {
        return {
            email: '',
            password: '',
            displayedServices: new Set(),
            loging: true,
            Nom: '',
            Prenom: '',
            Taille: '',
            DateNaissance: '',
            Sexe: '',
            doctor: [],
            image: 'https://hips.hearstapps.com/hmg-prod/images/portrait-of-a-happy-young-doctor-in-his-clinic-royalty-free-image-1661432441.jpg?crop=0.66698xw:1xh;center,top&resize=1200:*',
            number: 0,
            currentPage: 1,
            specialisteQuery: '',
            lieuxQuery: '',
            distance:'25000',
            dropdownVisible: false,

            servicesByDoctorId: {},
      perPage: 6,

        }
    },
    methods: {
        toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    updateDistance() {
      this.Getalldoctors();
    },
        Getalldoctors() {
        const queryParams = this.$route.query;

        // Ajouter 'distance' aux paramètres de requête
        queryParams.distance = this.distance;

        // Construction de l'URL avec les paramètres de requête
        let url = 'http://13.49.196.22/doctors';
        if (Object.keys(queryParams).length > 0) {
            url += '?';
            for (const key in queryParams) {
                url += `${key}=${encodeURIComponent(queryParams[key])}&`;
            }
            url = url.slice(0, -1); // Supprimer le dernier '&'
            console.log(url)
        }

        axios.get(url)
        .then(response => {
            console.log(response.data);
            this.doctor = response.data;

            // Initialiser un objet pour stocker les services par ID de médecin
            const servicesByDoctorId = {};

            // Boucle à travers chaque objet de médecin
            this.doctor.forEach(doc => {
                // Stocker les services de chaque médecin par son ID
                servicesByDoctorId[doc._id] = doc.service;
            });

            // Afficher les services stockés pour chaque ID de médecin
            console.log(servicesByDoctorId);

            this.number = this.doctor.length;
        })
        .catch(error => {
            console.log(error);
        });
    },
submitSearch() {
  if (!this.selectedAddress) {
        alert('Veuillez sélectionner une adresse.');
        return; // Empêche la soumission du formulaire si aucune adresse n'est sélectionnée
      }
      // Construire l'URL avec les champs du formulaire
      const query = `?specialite=${encodeURIComponent(this.specialisteQuery)}&ville=${encodeURIComponent(this.lieuxQuery)}`;
      
      // Rediriger vers /search avec les paramètres de requête
      window.location.href = `/search${query}`;
    },
        DetailsView(doctorId) {
  this.$router.push({ name: 'details', params: { id: doctorId } });
},
prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.currentPage++;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    getServiceList() {
        const services = new Set();
        this.doctor.forEach(doc => {
            doc.services.forEach(service => services.add(service));
        });
        return Array.from(services);
    },



        

       

    },
    mounted() {
        this.Getalldoctors()
        
    },
    computed: {
        paginatedDoctors() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.doctor.slice(startIndex, endIndex);
    },
    totalPage() {
      return Math.ceil(this.doctor.length / this.perPage);
    }
}
}

</script>

<style>
 .banner_filter {
    background-color: white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
  }

  .banner_filter label {
    font-weight: bold;
    margin-right: 10px;
  }

  .banner_filter input[type="number"],
  .banner_filter select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
    margin-right: 10px;
  }
@media only screen and (min-width: 768px){
    .mediatel{
    display: none;

  }
}

.searchbar2 {
    border-radius: 10px;
    box-shadow: 0 0 20px 0 #435f7133;
    display: flex;
    height: 60px;
    min-width: 600px;
    width: 80%;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

.border2 {
    border-right: 1px solid #c7c8c899;
    ;
}

.profile-logo {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    margin-right: 10px;
}

.profile-logo2 {
border-radius: 8px;
height: 230px;
width: 100%;
margin-right: 10px;
object-fit: cover;
}

.card_doctor {
    height: 230px;
    margin-top: 50px;
    margin-bottom: 50px;
   box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.27);
    border-radius: 10px;
    color: black;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
}

.button-nav2 {
    background-color: white;
    border: 1px solid rgb(13, 77, 195);
    border-radius: 20px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: small;
    color: rgb(13, 77, 195);
}

.button-nav2:hover {
    background-color: rgb(13, 77, 195);
    color: white;
}



.timeslot {
    background-color: rgb(13, 77, 195);
    border-radius: 3px;
    color: white;
    padding: 5px;
    margin: 5px;
    text-align: center;
    cursor: pointer;

}



li {
    list-style-type: none;
    color: black;
    font-weight: 600;
    font-size: small;
}

span {
    color: black;
    font-weight: bold;
    text-align: left;
}

a {
    text-decoration: none !important;
}

.empty_slot {
    align-items: center;
    display: flex;
    height: 34px;
    line-height: 34px;
    margin: 10px 0;
    width: 100%;
}

.empty_slot_dash {
    background-color: #2b466033;
    border-radius: 1px;
    height: 2px;
    margin: 0 auto;
    width: 12px;
}

.button_phone {
    background-color: #2b466033;
    border: none;
    border-radius: 7px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: small;
    color: white;
}

.banner_filter {
    height: 70px;
    margin-bottom: 50px;
}

.button_filter {
    background-color: white;
    box-shadow: 0 0 20px 0 #435f7133;
    border: 1px solid rgb(13, 77, 195);
    color: rgb(13, 77, 195);
    border-radius: 4px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: small;
}



:focus {
  outline: none;
}

.pagination-container {
    box-shadow: 0 0 20px 0 #435f7133;
    background-color: white;
    padding: 10px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border-radius: 40px;
  }
  .pagination-container button {
    margin: 0 5px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: 400;
    color: black;
    background-color: white ;
  }
  .pagination-container button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .pagination-container button.active {
    background-color: #1463F3;
    color: white;
    border-radius: 3px;
    font-weight: 600;

  }

  


  @media only screen and (max-width: 768px) {
    .searchbar2 {
    height: auto;
    flex-direction: column;
    padding: 10px;
    min-width: 100px;
    }
    .input_phone {
    width: 100% !important;
    border-right : 0px solid #ffffff !important;
    margin-left: 0px !important;
    margin-bottom: 10px;
    }

    .displaynone {
        display: none;

    }
    .card_phone {
        background-color: white;
        box-shadow: 0 0 20px 0 #435f7133;
        border-radius: 10px;
       
    }
    .pagination-container {
        width: 95%;
    }


    
  }
  

</style>
