import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ResultSearch from '../views/ResultView.vue'
import Details from '../views/DetailsView.vue'
import Login from '../views/LoginView.vue'
import RDV from '../views/RendezView.vue'
import AdminDashboard from '../views/Admin-DashboardView.vue'
import adminPlanning from '../views/admin-planning.vue'
import adminsettings from '../views/Admin-Settings.vue'
import adminPatient from '../views/AdminPatient.vue'
import AdminPatientDetails from '../views/AdminPatientDetails.vue'
import AdminService from '../views/AdminService.vue'
import practicien from '../views/practicienView.vue'
import patientdashboard from '../views/Patient-Dashboard.vue'
import resetpassword from '../views/ResetPassword.vue'
import resetpasswordTOKEN from '../views/resetpasswordTOKEN'
import dashboardtest from '../views/dashboardView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  { 
    path: '/test',
  name: 'dashboardtest',
  component: dashboardtest
  },

  {
    path: '/practicien',
    name: 'practicien',
    component: practicien
  },
  {
    path: '/admin/service',
    name: 'AdminService',
    component: AdminService
  },

  {
    path: '/admin/patient/:id',
    name: 'AdminPatientDetails',
    component: AdminPatientDetails,
    props: true // permet de transmettre les paramètres de route en tant que props au composant
  },

  {
    path: '/admin/patient',
    name: 'adminPatient',
    component: adminPatient
  },
  
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: resetpassword
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: resetpasswordTOKEN,
    props: true // Permet de transmettre les paramètres de route en tant que props au composant
  },
  
  
  {
    path: '/admin/settings',
    name: 'adminsettings',
    component: adminsettings
  },

  {
    path: '/dashboard',
    name: 'patientdashboard',
    component: patientdashboard
  },

  {
    path: '/admin/planning',
    name: 'adminPlanning',
    component: adminPlanning
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminDashboard
  },
  {
    path: '/rdv/:id',
    name: 'rdv',
    component: RDV
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/details/:id',
    name: 'details',
    component: Details
  },
  {
    path: '/search',
    name: 'search',
    component: ResultSearch
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // Vérifier si la route contient '/admin'
  if (to.path.startsWith('/admin')) {
    // Vérifier si le token est valide (vous devez implémenter cette logique)
    const token = localStorage.getItem('token'); // Vous pouvez stocker le token dans le local storage
    const UserTYPE = localStorage.getItem('userType');
    
    if (!token) {
      // Rediriger vers la page de connexion
    
      next('/practicien');
    }
    else if(UserTYPE == 'patient'){
      next('/');
    }
    
    else {
     
      console.log('Token valide');
      console.log(token);
      next();
    }
  } else {
    // Si ce n'est pas une route /admin, laissez l'utilisateur accéder à la route demandée
    next();
  }
});

export default router
