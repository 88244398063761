<template>

<div class="background1">
  <div class="container align-items-center">
   
    <div class="row align-items-center">
  <div class="col-md-5 order-md-last text-center-md">
    <img class="no-display" src="../assets/toppng.com-doctors-582x600.png" alt="appointment" height="400px" width="" style="margin-top: 100px;">
  </div>
  <div class="col-md-7 text-md-start">
    <div>
      <h1 class="title d-flex justify-content-center">PRENEZ RENDEZ-VOUS <br> EN LIGNE</h1> 
    </div>
   

    <!-- Mot avec effet typing  -->
    <h1 style="color: white;
font-weight: bold;
font-size: 60px; min-height: 100px;">{{ typedText }}</h1>


   
  </div>
  
</div>
<search-bar></search-bar>



  </div>
</div>





<!-- Comment Ca Marche -->

<!-- Comment Ca marche -->

<div style="margin-top: 190px;" class="container">
    <h2 style="font-size: 40px;">
        Comment Prendre <span class="highlight">Rendez-vous</span>
    </h2>
    <div style="place-content: center;" class="d-flex mt-5">
        <div class="row">
            <!-- Première carte -->
            <div style="margin-top: 5px; margin-bottom: 5px; " class="col-md-3">
                <div style="background-color: #FFFF; border-radius: 5px; padding: 60px 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; text-align: -webkit-center;">
                    <svg class="mb-2 d-flex align-item-start" style="height: 50px; text-align: center;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      
                      <path fill="rgb(4, 56, 115)" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-96 55.2C54 332.9 0 401.3 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-81-54-149.4-128-171.1V362c27.6 7.1 48 32.2 48 62v40c0 8.8-7.2 16-16 16H336c-8.8 0-16-7.2-16-16s7.2-16 16-16V424c0-17.7-14.3-32-32-32s-32 14.3-32 32v24c8.8 0 16 7.2 16 16s-7.2 16-16 16H256c-8.8 0-16-7.2-16-16V424c0-29.8 20.4-54.9 48-62V304.9c-6-.6-12.1-.9-18.3-.9H178.3c-6.2 0-12.3 .3-18.3 .9v65.4c23.1 6.9 40 28.3 40 53.7c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-25.4 16.9-46.8 40-53.7V311.2zM144 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>

                    </svg>
                  
                    <h4>Choisissez votre médecin</h4>
                    <p style="text-align: left;">
                        Choisissez parmi nos médecins généralistes disponibles.
                    </p>
                </div>
            </div>
            <!-- Deuxième carte -->
            <div style="margin-top: 5px; margin-bottom: 5px; " class="col-md-3">
                <div style="background-color: #4F9CF9; border-radius: 5px; padding: 60px 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; text-align: -webkit-center;">
                    <svg class="mb-2 d-flex align-item-start" style="height: 50px; text-align: left;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#FFFF" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/>
                    </svg>
                    
                    <h4>Sélectionnez une date</h4>
                    <p style="color: white; text-align: left;">
                        Choisissez une date et une heure pour votre rendez-vous.
                    </p>
                </div>
            </div>
            <!-- Troisième carte -->
            <div style="margin-top: 5px; margin-bottom: 5px; " class="col-md-3">
                <div style="background-color: #FFFF; border-radius: 5px; padding: 60px 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;text-align: -webkit-center;">
                    <svg class="mb-2 d-flex align-item-start" style="height: 50px; text-align: left;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="rgb(4, 56, 115)" d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    
                    <h4>Confirmez votre rendez-vous</h4>
                    <p style=" text-align: left;">
                        Vérifiez toutes les informations et confirmez votre rendez-vous.
                    </p>
                </div>
            </div>
            <!-- Nouvelle carte -->
            <div style="margin-top: 5px; margin-bottom: 5px; " class="col-md-3">
                <div style="background-color: #4F9CF9; border-radius: 5px; padding: 60px 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;text-align: -webkit-center;">
                    <svg class="mb-2 d-flex align-item-start" style="height: 50px; text-align: left;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#FFFF" d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                    
                    <h4> Rendez-vous confirmé</h4>
                    <p style="color: white; text-align: left;">
                        Votre rendez-vous est confirmé. Préparez-vous à la date choisie.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top: 100px;" class="container">
  <div class="row">
    <div class="col-md-6 d-flex align-items-center">
      <div>
        <p style="color: #4F9CF9; text-align: left;">
         + 500 professionnel Medical Inscrit 
      </p>
      <h3 style="font-family: Arial Black; font-size: 30px; text-align: left;">
        Prenez rendez-vous médical en toute simplicité !
      </h3>
      <p class="mt-5" style="text-align: left; font-size: 14px;">
        Medico.pt est une plateforme de prise de rendez-vous médicaux en ligne. Nous vous permettons de trouver un spécialiste près de chez vous, de prendre rendez-vous en ligne et de consulter votre spécialiste en toute simplicité.
      </p>
      <button class="mt-4"
                                        style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                       Recherchez Un Medecin
                                   </button>
    </div>
      </div>
     
    <div class="col-md-6">
      <img class="img_phone" style="border-radius: 10px; object-fit: cover;" src="https://hms.harvard.edu/sites/default/files/media/800-Doctors-Talking-GettyImages-1421919753.jpg" alt="appointment" height="500px" width="500px">
    </div>
  </div>
</div>
<div style="margin-top: 100px;" class="container">
  <div class="row">
    <div class="col-md-6">
      <img class="no-display" style="border-radius: 10px; object-fit: cover;" src="https://img.freepik.com/free-photo/doctor-patient_1421-64.jpg?t=st=1719005294~exp=1719008894~hmac=173cf39c03d3df7bbea03d4f3913d42968ff15fb71537627e72bdb4fe5e7e6cb&w=2000" alt="appointment" height="500px" width="500px">
    </div>
    <div class="col-md-6 d-flex align-items-center">
      <div>
      <h3 style="font-family: Arial Black; font-size: 30px; text-align: left;">
        Pourquoi choisir <span class="highlight">Medico.PT?</span>
      </h3>
      <div style="place-content: center;" class="d-flex mt-2">
        <ul>
            <li style="margin-bottom: 20px;">
                <h4 style="color: #043873;">Accès facile aux spécialistes</h4>
                <p>Explorez notre réseau de plus de 500 professionnels de la santé, incluant des médecins généralistes et des spécialistes dans diverses disciplines médicales.</p>
            </li>
            <li style="margin-bottom: 20px;">
                <h4 style="color: #043873;">Prise de rendez-vous en ligne</h4>
                <p>Réservez votre consultation en quelques clics, 24/7, depuis n'importe quel appareil.</p>
            </li>
            <li style="margin-bottom: 20px;">
                <h4 style="color: #043873;">Confirmation instantanée</h4>
                <p>Recevez une confirmation immédiate de votre rendez-vous, avec toutes les informations nécessaires.</p>
            </li>
            <li style="margin-bottom: 20px;">
                <h4 style="color: #043873;">Témoignages de patients</h4>
                <p>Découvrez ce que nos patients disent à propos de leur expérience avec Medico.PT.</p>
            </li>
        </ul>
    </div>
      <button class="mt-2"
                                        style="border: none; border-radius: 8px; background-color: #4F9CF9; color: #FFFF; padding: 10px 20px; font-size: 13px;">
                                       Recherchez Un Medecin
                                   </button>
    </div>
      </div>
     
    
  </div>
</div>
<div class="container" style="margin-top: 140px;">
    <h2 style="font-size: 40px;">
      Ce que pensent <span class="highlight">Nos Patients</span>
    </h2>
    <div class="carousel slide" id="testimonialsCarousel" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item" v-for="(testimonial, index) in testimonials" :key="index" :class="{ active: index === 0 }">
          <div class="d-flex justify-content-center">
            <div style="  margin-top: 100px; margin-bottom: 10px; min-height: 300px;" class="card mx-2 testimonial-card">
              <img :src="testimonial.image" class="card-img-top" alt="Doctor Image" />
              <div class="card-body text-center">
                <h5 class="card-title">{{ testimonial.name }}</h5>
                <p class="card-text"><small class="text-muted">{{ testimonial.title }}</small></p>
                <p class="card-text">{{ testimonial.feedback }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-top: 100px;" class="container">
    <h3 style="font-family: Arial Black; font-size: 30px; text-align: center;">
    Nos Questions Frequentes
    </h3>
    <div class="row">
      <div class="col-md-6">
        <img class="img_phone"
          style="border-radius: 10px; object-fit: cover;"
          src="https://img.freepik.com/free-vector/website-faq-section-user-help-desk-customer-support-frequently-asked-questions-problem-solution-quiz-game-confused-man-cartoon-character_335657-1602.jpg?t=st=1719006090~exp=1719009690~hmac=f9edc197c6e81c2b872bfd88de2161433d80092527923f98a6e7f30a03d1ad62&w=1380"
          alt="appointment"
          height="500px"
          width="500px"
        />
      </div>
      <div class="col-md-6 d-flex align-items-center">
        <div id="faq" class="faq-body">
      <div class="faq-header">
      </div>
      <div class="faq-list">
          <div>
            <details open>
              <summary title="How can I pay for my appointment?">How can I pay for my appointment?</summary>
              <p class="faq-content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed illo quod debitis ducimus iste soluta rerum ipsa consequuntur minus quae. Ea minus ex corporis, pariatur quia rerum sequi iusto odit enim expedita fugiat, assumenda molestiae earum iste blanditiis, ipsum ratione.</p>
            </details>
            </div>
          <div>
            <details>
              <summary title="What can I expect at my first consultation?">What can I expect at my first consultation?</summary>
              <p class="faq-content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi, molestias similique! Molestiae sapiente omnis, illo facere odit reprehenderit eveniet consequuntur sit minus adipisci temporibus eius inventore quidem. Dignissimos, facere quae. Rem quas a laborum est officia pariatur voluptatum iusto perferendis aut labore fugit magni inventore nulla architecto, velit, facilis itaque.</p>
            </details>
            </div>
          <div>
            
            <details>
              <summary title="What are your opening hours?">What are your opening hours?</summary>
              <p class="faq-content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos error ea accusantium? Sunt cum in, repudiandae et facere at nesciunt commodi non quia earum libero aliquid labore obcaecati repellendus consequatur! Nesciunt impedit ducimus illum unde optio veritatis atque facere, voluptate a odio asperiores laudantium rerum.</p>
            </details>
            </div>
          <div>
            <details>
              <summary title="Do I need a referral?">Do I need a referral?</summary>
              <p class="faq-content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis quisquam numquam labore explicabo cupiditate laboriosam. Ipsam explicabo possimus illum aspernatur.</p>
            </details>
            </div>
            <div>
            <details>
              <summary title="Do I need a referral?">Do I need a referral?</summary>
              <p class="faq-content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis quisquam numquam labore explicabo cupiditate laboriosam. Ipsam explicabo possimus illum aspernatur.</p>
            </details>
            </div>
            <div>
            <details>
              <summary title="Do I need a referral?">Do I need a referral?</summary>
              <p class="faq-content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis quisquam numquam labore explicabo cupiditate laboriosam. Ipsam explicabo possimus illum aspernatur.</p>
            </details>
            </div>

      </div>
    </div>
      </div>
    </div>
  </div>








<!-- Blog -->


<!-- Blog -->












</template>


<script>
import SearchBar from '../components/searchbarComponent.vue';
import MapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

export default {
  components: {
        'search-bar': SearchBar
    },
  data() {
    return {
      words: [
        'Médico de Família',
        'Cirurgião',
        'Dentista',
        'Enfermeiro(a)',
        'Farmacêutico(a)',
        'Fonoaudiólogo(a)',
        'Psicólogo(a)',
        'Fisioterapeuta',
        'Obstetriz',
        'Oftalmologista',
        'Pediatra',
        'Ginecologista e Obstetra',
        'Radiologista',
        'Anestesista',
        'Cardiologista',
        'Dermatologista',
        'Gastroenterologista',
        'Neurologista',
        'Oncologista',
        'Pneumologista',
        'Reumatologista',
        'Urologista',
        'Quiropraxista',
        'Osteopata',
        'Podólogo'
      ],
      currentIndex: 0,
      typedText: '',
      specialisteQuery: '',
      specialisteSuggestions: [],
      lieuxQuery: '',
      lieuxAutocomplete: [],
      address: '',
      searchResults: [],
      faqs: [
        {
          question: "Comment créer un compte?",
          answer:
            "Pour créer un compte, cliquez sur le bouton 'S'inscrire' en haut à droite de la page d'accueil, puis suivez les instructions pour entrer vos informations personnelles et créer un mot de passe.",
          show: false,
        },
        {
          question: "Comment rechercher un médecin?",
          answer:
            "Utilisez la barre de recherche pour entrer le nom, la spécialité ou la localisation du médecin que vous cherchez. Vous pouvez également filtrer les résultats selon vos préférences.",
          show: false,
        },
        {
          question: "Quels sont les modes de paiement acceptés?",
          answer:
            "Nous acceptons les paiements par carte de crédit, carte de débit et PayPal. Toutes les transactions sont sécurisées.",
          show: false,
        },
        {
          question: "Comment annuler ou reprogrammer un rendez-vous?",
          answer:
            "Pour annuler ou reprogrammer un rendez-vous, accédez à la section 'Mes rendez-vous', sélectionnez le rendez-vous que vous souhaitez modifier, et choisissez l'option d'annulation ou de reprogrammation.",
          show: false,
        },
      ],
      testimonials: [
        {
          image: 'https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360',
          name: 'Enzo Viana',
          title: 'Médecin Généraliste',
          feedback: 'Whitepate est conçu comme un outil de collaboration pour les entreprises, offrant une solution complète de gestion de projet.'
        },
        {
          image: 'https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360',
          name: 'Marie Dupont',
          title: 'Cardiologue',
          feedback: 'Whitepate a grandement amélioré notre efficacité et la communication au sein de notre équipe.'
        },
        {
          image: 'https://img.freepik.com/premium-vector/doctor-profile-with-medical-service-icon_617655-48.jpg?w=360',
          name: 'Paul Martin',
          title: 'Chirurgien',
          feedback: 'Grâce à Whitepate, nous avons pu mieux organiser nos projets et respecter les délais.'
        }
      ],
      selectedAddress: null,
      showSuggestions: false,
      showSearchResults: false,
        selectedLatitude: null,
        selectedLongitude: null
    };
  },
  mounted() {
    this.typeNextWord();
  },
  computed: {
    filteredWords() {
      return this.words.filter(word => word.toLowerCase().includes(this.specialisteQuery.toLowerCase()));
    }
  },
  methods: {
    showResults() {
        this.showSearchResults = true;
    },
    selectAddress(result) {
  this.address = result.place_name;
  this.selectedAddress = result;
  if (result.center && result.center.length >= 2) {
    this.selectedLatitude = result.center[1]; // Latitude de l'adresse sélectionnée
    this.selectedLongitude = result.center[0]; // Longitude de l'adresse sélectionnée
  }
  this.showSearchResults = false; // Cacher la liste après avoir sélectionné un lieu
},

selectSuggestion(suggestion) {
  this.specialisteQuery = suggestion;
  this.showSuggestions = false; // Cache les suggestions une fois que l'utilisateur en a sélectionné une
},

async submitSearch() {
  if (!this.selectedAddress && this.searchResults.length > 0) {
    // Si aucune adresse n'est sélectionnée, sélectionner la première suggestion
    this.selectAddress(this.searchResults[0]);
  }

  // Validation des données
  if (!this.selectedAddress || !this.selectedLatitude || !this.selectedLongitude) {
    console.error('Veuillez sélectionner une adresse valide.');
    return;
  }

  // Construction de l'URL avec les champs du formulaire et les coordonnées géographiques
  const query = `?specialite=${encodeURIComponent(this.specialisteQuery)}&ville=${encodeURIComponent(this.lieuxQuery)}&latitude=${encodeURIComponent(this.selectedLatitude)}&longitude=${encodeURIComponent(this.selectedLongitude)}`;

  // Redirection vers /search avec les paramètres de requête
  window.location.href = `/search${query}`;
},

    async searchAddress() {
        const mapboxClient = MapboxGeocoding({ accessToken: 'pk.eyJ1IjoiZW56b3YiLCJhIjoiY2x3dDhuZGtxMDE3dDJqc2F0dHRnMGhocyJ9.w4ghuWWoBjSzHyHKHx8R9A' }); // Remplacez par votre token Mapbox

        const response = await mapboxClient.forwardGeocode({
            query: this.address,
            countries: ['FR'],
            limit: 5, // Limite le nombre de résultats
        }).send();

        if (response && response.body && response.body.features) {
            this.searchResults = response.body.features;
        }
    },

    typeNextWord() {
      const nextWord = this.words[this.currentIndex];
      this.typedText = '';

      let i = 0;
      const interval = setInterval(() => {
        this.typedText += nextWord[i];
        i++;
        if (i === nextWord.length) {
          clearInterval(interval);
          setTimeout(() => {
            this.deleteWord(nextWord);
          }, 1000);
        }
      }, 100);
    },
    deleteWord(word) {
      let i = word.length - 1;
      const interval = setInterval(() => {
        this.typedText = this.typedText.slice(0, -1);
        i--;
        if (i === -1) {
          clearInterval(interval);
          setTimeout(() => {
            this.currentIndex = (this.currentIndex + 1) % this.words.length;
            this.typeNextWord();
          }, 1000);
        }
      }, 100);
    },
    fetchSpecialisteSuggestions() {
  // Votre logique pour récupérer les suggestions de spécialistes
  // Par exemple, vous pouvez utiliser une requête API ou une liste prédéfinie
  
  // Simulons des données de suggestions de spécialistes (remplacez ceci par votre propre logique)
  const suggestions = ['Cardiologue', 'Dermatologue', 'Ophtalmologiste', 'Gynécologue', 'Pédiatre'];
  
  // Filtrer les suggestions en fonction de la requête de l'utilisateur
  const filteredSuggestions = suggestions.filter(suggestion =>
    suggestion.toLowerCase().includes(this.specialisteQuery.toLowerCase())
  );
  
  // Mettre à jour this.specialisteSuggestions avec les résultats filtrés
  this.specialisteSuggestions = filteredSuggestions;
},

fetchLieuxAutocomplete() {
  // Votre logique pour récupérer les autocomplétions de lieux
  // Par exemple, vous pouvez utiliser une requête API ou une liste prédéfinie
  
  // Simulons des données d'autocomplétion de lieux (remplacez ceci par votre propre logique)
  const lieux = ['Paris', 'Marseille', 'Lyon', 'Toulouse', 'Nice', 'Nantes', 'Strasbourg', 'Montpellier'];
  
  // Filtrer les lieux en fonction de la requête de l'utilisateur
  const filteredLieux = lieux.filter(lieu =>
    lieu.toLowerCase().includes(this.lieuxQuery.toLowerCase())
  );
  
  // Mettre à jour this.lieuxAutocomplete avec les résultats filtrés
  this.lieuxAutocomplete = filteredLieux;
},
selectSpecialisteSuggestion(suggestion) {
      this.specialisteQuery = suggestion;
      this.specialisteSuggestions = []; // Efface les suggestions une fois que l'utilisateur en a choisi une
    },
    selectLieuxSuggestion(suggestion) {
      this.lieuxQuery = suggestion;
      this.lieuxAutocomplete = []; // Efface les autocomplétions une fois que l'utilisateur en a choisi une
    },
    toggleFaq(index) {
      this.faqs[index].show = !this.faqs[index].show;
    },
  }
};
</script>

<style>

.active{
  background-color: transparent;
}

.carousel-item {
  background-color: transparent;
}

.testimonial-card {
  width: 700px; /* Largeur plus grande */
  height: 100px; /* Hauteur moins grande */
  background-color: #FFFFFF;
  border: none !important; 
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.testimonial-card .card-img-top {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 20px auto 0;
}


.searchbar {
  border-radius: 15px;
  box-shadow: 0 0 20px 0 #435f7133;
  display: flex;
  height: 60px;
  min-width: 600px;
  width: 90% !important;
  background-color: white;
  margin-right: auto;
  margin-left: auto;
  padding: 7px;
}

.background1 {
  background-color: rgb(4, 56, 115);
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 2em;
  margin: 0;
  padding: 0;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  text-align: left;
}

.input-specialiste,
.input-lieux {
  flex: 1;
  border: none;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  width: 90%;
}

.search-button {
  border: none;
  background-color: rgb(4, 56, 115);
  
  color: white;
  border-radius: 2px 15px 15px 2px;
  padding: 10px ;
  cursor: pointer;
}

.card {
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 #435f7133;
}

.number{
  font-size: 2em;
  font-weight: bold;
  color: white;
}

.button-nav{
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: small;
  color: #2c3e50;

}

.button-nav:hover{
  color: #00b8ff;
}

li{
  list-style: none;
  margin: 10px;
  
  font-weight: bold;
  color: white;
}

.card2{
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 #435f7133;
  border: none;
  padding: 10px;
}

.card_categorie {
        position: relative;
        margin: 10px;
        border-radius: 20px;
        box-shadow: 0 0 20px 0 #435f7133;
        height: 300px;
        border: none;
        cursor: pointer;
    }

    .card_categorie::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Couleur de fond semi-transparente */
        border-radius: 20px;
    }

    .card_text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 24px;
        text-align: center;
        z-index: 1;
        font-weight: bold;
    }

.button-article{
  background-color: #4E6DCE;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: small;
  color: white;
}


.point {
width: 6px;
height: 6px;
background-color: #ccc;
border-radius: 50%;
margin: 15px;
}

.svg_how {
height: 50px;
width: 50px;
box-shadow: 0 0 20px 0 #435f7133;
border-radius: 10px;
padding: 13px;
margin-left: auto;
margin-right: auto;
}

.read_more_button {
  background-color: rgb(4, 56, 115);
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: small;
  color: white;
}

.suggestions,
.autocompletions {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  width: calc(100% - 22px); /* Prend la largeur du parent moins les marges */
}

.suggestions ul,
.autocompletions ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.suggestions .suggestion-item,
.autocompletions .suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestions .suggestion-item:hover,
.autocompletions .suggestion-item:hover {
  background-color: #f2f2f2;
}
.search-container {
  position: relative;
  display: inline-block;
  width: 40%;
  text-align: left;
}

.search-results {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 #435f7133;
  background-color: white;
  position: absolute;
  width: calc(100% - 20px); /* Largeur égale à la largeur du champ "Lieux" */
  max-height: 200px; /* Limitez la hauteur de la liste pour ajouter un défilement si nécessaire */
  overflow-y: auto; /* Ajoutez un défilement vertical si la liste dépasse la hauteur définie */
  top: calc(100% + 5px); /* Placez la liste juste en dessous du champ "Lieux" */
  left: 0;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}


p {
    line-height: 1.4em;
    color: #9e9e9e;
}

.faq-title {
    font-size: 2em;
    margin: 0.4em 0;
}

div.seperator {
    width: 7.5em;
    background-color: #4F9CF9;
    height: 0.17em;
    margin-left: -1.8em;
}

.faq-list > div {
    border-bottom: 0.07em solid #ededed;
    padding: 1em 0em;
} 

.faq-list > div:last-child {
    border: unset;
}

details > summary {
    list-style: none;
}
details > summary::-webkit-details-marker {
    display: none;
}

summary {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
    transition: all 0.3s ease;
}

summary:hover {
    color: #4F9CF9;
}

details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
}

@keyframes sweep {
    0%    {opacity: 0; margin-left: -10px}
    100%  {opacity: 1; margin-left: 55px}
}

details[open] summary {
    color: #4F9CF9;
}

details[open] p {
    border-left: 2px solid #4F9CF9;
    margin-left: 55px;
    padding-left: 25px;
    opacity: 100;
    transition: all 3s ease;
}

details[open] summary:after {
    content: "-";
    font-size: 3.2em;
    margin: -33px 0.35em 0 0;
    font-weight: 200;
}


.faq-list {
    margin: 1em auto;
    padding: 2em 0;
}

summary::-webkit-details-marker {
    display: none;
}

summary:after {
    background: transparent;
    border-radius: 0.3em;
    content: "+";
    color: #4F9CF9;
    float: left;
    font-size: 1.8em;
    font-weight: bold;
    margin: -0.3em 0.65em 0 0;
    padding: 0;
    text-align: center;
    width: 25px;
}


@media only screen and (max-width: 768px) {
  summary{
    font-size: 14px
  }
  .card-container {
                padding: 20px;
            }

            h2 {
                font-size: 30px;
            }

            h3 {
                font-size: 25px;
            }

            h4 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }

            .card-padding {
                padding: 30px 20px;
            }

            .highlight {
                color: #4F9CF9;
            }

            .faq-container {
                margin-top: 50px;
            }

            .faq-item {
                margin-bottom: 15px;
            }
        
  .background1 {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .number{
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-top: 30px ;
}
h6{
  font-size: 13px !important ;
}
  .title {
    font-size: 1.5em;
    text-align: center;
  }
  .svg_how {
    height: 40px;
    width: 40px;
    padding: 10px;
    margin: 10px auto;
  }
  .point {
    margin: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  .block {
    display: block !important;
    
  }
  .col-md-2 {
    text-align: center;
  }
  .col-md-5 img {
    height: auto;
    width: 100%;
    margin-top: 50px;
  }
  .searchbar {
    height: auto;
    flex-direction: column;
    padding: 10px;
    min-width: 100px;
  }
  .input-specialiste,
  .input-lieux {
    margin-bottom: 10px;
    width: 100%;
  }
  .container.mb-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .search-button {
  border: none;
  background-color: rgb(4, 56, 115);
  
  color: white;
  border-radius:15px;
  padding: 10px ;
  cursor: pointer;
}
.img_phone{
  margin-top: 50px;
  height: 200px;
  width: auto;
}
.no-display{
  display: none;
}
  .accordion-item {
    margin-bottom: 10px;
  }
  .col-md-7,
  .col-md-5 {
    width: 100%;
  }
  .card2 img {
    height: auto;
    width: 100%;
  }
  .d-flex img {
    height: auto;
    width: 50%;
    margin-right: 10px;
  }
  .button-article {
    width: 100%;
    margin-bottom: 10px;
  }
}

</style>
